import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {EduMaterialComponent} from './components/edu-material/edu-material.component';
import {ExamComponent} from './components/exam/exam.component';
import {ExamSelectorComponent} from './components/exam-selector/exam-selector.component';
import {ResultComponent} from './components/result/result.component';
import {RedirectGuard} from './network/guards/redirect.guard';
import {AuthGuard} from './network/guards/auth-guard.service';
import {PracticeComponent} from './components/practice/practice.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: '', canActivate: [AuthGuard], children: [
      { path: 'edu-material', component: EduMaterialComponent },
      { path: 'exam-selector', component: ExamSelectorComponent },
      { path: 'test-exam', component: ExamComponent },
      { path: 'practice', component: PracticeComponent },
      { path: 'result', component: ResultComponent }
    ]
  },
  {
    path: 'vizenjo',
    canActivate: [RedirectGuard],
    component: DashboardComponent,
    data: {
      externalUrl: 'https://vizenjo.hu/'
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled', scrollPositionRestoration: 'top', scrollOffset: [0, 110]})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
