<!-- Image and text -->
<mdb-navbar SideClass="navbar fixed-top bg-white" [containerInside]="false">
  <mdb-navbar-brand>
    <a class="navbar-brand" href="#">
      <img src="../../../assets/images/header.svg" class="d-inline-block mr-lg-4 mr-md-0 mr-0 ml-lg-4 ml-md-0 ml-0 logo" alt="">
    </a>
  </mdb-navbar-brand>
  <div class="navbar-text mr-auto">
      <span class="header-text">
        {{ topTitle }}
      </span>
    <br>
    <span class="header-page-text">
        {{ pageTitle }}
      </span>
  </div>

  <ul class="navbar-nav mr-lg-4 mr-md-0 mr-0 nav-flex-icons">
    <li class="mr-2">
      <button class="button-outline d-lg-block d-md-none d-none" data-toggle="modal" data-target="#feedbackModal">
        Hibabejelentés
      </button>
    </li>
    <li>
      <button class="button-primary" (click)="logout()">
        Kilépés
      </button>
    </li>
  </ul>
</mdb-navbar>

<div id="feedbackModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <app-feedback-modal class="modal-content"></app-feedback-modal>
  </div>
</div>
