import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HierarchyTopic} from '../../network/models/HierarchyTopic';
import {NetworkService} from '../../network/services/network.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Chapter} from '../../network/models/Chapter';
import {HierarchyChapter} from '../../network/models/HierarchyChapter';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../network/services/auth.service';
import {ViewportScroller} from '@angular/common';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';

@Component({
  selector: 'app-edu-material',
  templateUrl: './edu-material.component.html',
  styleUrls: ['./edu-material.component.scss']
})
export class EduMaterialComponent implements OnInit, AfterViewInit {

  topic: HierarchyTopic;
  selectedChapter: Chapter = null;
  selectedChapterProgress = 0;

  studentCourseId: number;
  topicId: number;
  courseName: string;

  preScroll = false;

  pdfSrc: any;

  progressRef: NgProgressRef;

  constructor(private network: NetworkService,
              private route: ActivatedRoute,
              private auth: AuthService,
              private router: Router,
              private viewportScroller: ViewportScroller,
              private progress: NgProgress) { }

  ngOnInit(): void {
    this.progressRef = this.progress.ref('progressBar');
    this.preScroll = false;
    this.topicId = +this.route.snapshot.queryParams.topicId;
    this.studentCourseId = +this.route.snapshot.queryParams.studentCourseId;
    const preselectedChapter = +this.route.snapshot.queryParams.selectedChapter;

    this.network.getTopicHierarchy(this.studentCourseId).subscribe(
      hierarchy => {
        this.topic = hierarchy.find( topic => topic.topicId === this.topicId);
        this.courseName = this.topic.topicName;
        if (preselectedChapter) {
          this.chapterSelect(preselectedChapter);
        } else {
          this.chapterSelect(this.topic.subtopics[0].chapters[0].chapterId);
        }
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() =>
      {
        const preselectedChapter = +this.route.snapshot.queryParams.selectedChapter;
        if (preselectedChapter) {
          const searchedElement = document.getElementById('chapter_' + preselectedChapter);
          if (searchedElement) {
            searchedElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
          }
        }
        const preselectedAnchor = this.route.snapshot.fragment;
        if (preselectedAnchor) {
          const searchedElement = document.getElementById(preselectedAnchor);
          if (searchedElement) {
            const offset = searchedElement.getBoundingClientRect().top + window.pageYOffset - 110;
            window.scrollTo({ top: offset });
          }
        }
      },
      500);
  }

  chapterSelect(chapterId: number): void {
    this.auth.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.auth.logout();
        this.router.navigate(['vizenjo']);
      } else {
        this.network.getChapter(chapterId).subscribe(
          chapter => {
            this.progressRef.start();
            if (chapter.textBody) {
              chapter.textBody = this.createUrlsForImages(chapter.textBody);
              chapter.textBody = this.createUrlsForAnchors(chapter.textBody);
              this.progressRef.complete();
            } else {
              this.network.getPdfResource(chapter.resources.find(r => r.resourceType === 'PDF').resourceId + 1000).subscribe(pdf => {
                this.createPdfFromBlob(pdf);
              }, () => this.progressRef.complete());
            }
            this.selectedChapter = chapter;
            this.getSelectedChapterProgress();
            this.scrollToTop();
          }
        );
      }
    });
  }

  private createUrlsForImages(textBody: string): string {
    let textBodyWithImageUrls = textBody;
    const imageRegex = /<img.*?src\s*=\s*"((?!data:.+?).+?)"/g;
    let match = imageRegex.exec(textBody);
    while (match != null) {
      const imageName = match[1];
      textBodyWithImageUrls = textBodyWithImageUrls.replace(imageName,
        environment.baseUrl + '/resources/resource_codes/' + imageName + '?access_token=' + this.auth.getToken());
      match = imageRegex.exec(textBody);
    }
    return textBodyWithImageUrls;
  }

  private createUrlsForAnchors(textBody: string): string {
    let textBodyWithAnchorUrls = textBody;
    const anchorRegex = /<a.*?href\s*=\s*"(.+?)"/g;
    let match = anchorRegex.exec(textBody);
    while (match != null) {
      const anchorName = match[1];
      textBodyWithAnchorUrls = textBodyWithAnchorUrls.replace(anchorName, this.router.url.split('#')[0] + anchorName);
      match = anchorRegex.exec(textBody);
    }
    return textBodyWithAnchorUrls;
  }

  getSelectedChapterProgress(): void {
    this.network.getChapterStatistics(this.studentCourseId, this.selectedChapter.chapterId).subscribe(stats => {
      this.selectedChapterProgress = stats.learnedPercent;
    });
  }

  private scrollToTop(): void {
    window.scrollTo( 0, 0 );
  }

  getChapterClass(chapter: HierarchyChapter): string {
    let selected = false;
    if (this.selectedChapter) {
      selected = this.selectedChapter.chapterId === chapter.chapterId;
    }
    const completed = chapter.learnedPercent === 100;
    if (selected) {
      if (completed) {
        return 'completed-active-chapter-container';
      } else {
        return 'active-chapter-container';
      }
    } else {
      if (completed) {
        return 'completed-chapter-container';
      } else {
        return 'chapter-container';
      }
    }
  }

  scrollToAnchor(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  openSideNav(): void {
    document.getElementById('mySidenav').style.width = '300px';
  }

  closeSideNav(): void {
    document.getElementById('mySidenav').style.width = '0';
  }

  createPdfFromBlob(pdf: Blob): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.pdfSrc = reader.result;
      this.progressRef.complete();
    };
    reader.readAsArrayBuffer(pdf);
  }
}
