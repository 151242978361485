import {Component, Input, OnInit} from '@angular/core';
import {HierarchyTopic} from '../../network/models/HierarchyTopic';
import {NetworkService} from '../../network/services/network.service';
import {ActivatedRoute} from '@angular/router';
import {HierarchyChapter} from '../../network/models/HierarchyChapter';

@Component({
  selector: 'app-exam-selector',
  templateUrl: './exam-selector.component.html',
  styleUrls: ['./exam-selector.component.scss']
})
export class ExamSelectorComponent implements OnInit {

  topic: HierarchyTopic;
  selectedChapter: HierarchyChapter = null;

  studentCourseId: number;
  topicId: number;
  courseName: string;

  constructor(private network: NetworkService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.topicId = +this.route.snapshot.queryParams.topicId;
    this.studentCourseId = +this.route.snapshot.queryParams.studentCourseId;

    this.network.getTopicHierarchy(this.studentCourseId).subscribe(
      hierarchy => {
        this.topic = hierarchy.find( topic => topic.topicId === this.topicId);
        this.courseName = this.topic.topicName;
      }
    );
  }

  selectChapter(chapter: HierarchyChapter): void {
    this.selectedChapter = chapter;
  }
}
