import {Component, Input, OnInit} from '@angular/core';
import {TestExamSummary} from '../../network/models/TestExamSummary';
import {ActivatedRoute, Router} from '@angular/router';
import { HostListener } from '@angular/core';
import { TestExamQuestion } from 'src/app/network/models/TestExamQuestion';
import { NetworkService } from 'src/app/network/services/network.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  testExamSummary: TestExamSummary;

  spentTimeMinutes = '';
  spentTimeSeconds = '';

  studentCourseId: number;
  topicId: number;

  desktopView = true;

  openedIdx: number;

  imageToShow: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private network: NetworkService) { }

  ngOnInit(): void {

    this.studentCourseId = +this.route.snapshot.queryParams.studentCourseId;
    this.topicId = +this.route.snapshot.queryParams.topicId;
    this.testExamSummary = history.state.testExamSummary;

    if (!this.testExamSummary) {
      this.router.navigate(['/']);
    } else {
      let spentTime = (new Date(this.testExamSummary.endTime).getTime() - new Date(this.testExamSummary.startTime).getTime()) / 1000;
      spentTime = Math.round(spentTime);
      const spentTimeSecondsInt = spentTime % 60;
      const spentTimeMinutesInt = (spentTime - spentTimeSecondsInt) / 60;
      if (spentTimeSecondsInt < 10) {
        this.spentTimeSeconds = '0';
      }
      if (spentTimeMinutesInt < 10) {
        this.spentTimeMinutes = '0';
      }
      this.spentTimeSeconds += spentTimeSecondsInt.toString();
      this.spentTimeMinutes += spentTimeMinutesInt.toString();
    }

    if (window.innerWidth <= 768) {
      this.desktopView = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (event.target.innerWidth <= 768) {
      this.desktopView = false;
    } else {
      this.desktopView = true;
    }
  }

  onPanelOpened(question: TestExamQuestion, idx: number): void {
    this.openedIdx = idx;
    this.imageToShow = null;
    if (question.picture)
    {
      this.network.getImageResource(question.picture.resourceId).subscribe(
        image => {
          this.createImageFromBlob(image);
        }
      );
    }
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
