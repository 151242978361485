import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NetworkService} from '../../../../network/services/network.service';
import {MyDocument} from '../../../../network/models/MyDocument';
import {Registration} from '../../../../network/models/Registration';
import {ExamCalendar} from '../../../../network/models/ExamCalendar';
import {ExamTypes} from './enums/ExamTypes';
import {DocumentTypes} from './enums/DocumentTypes';
import {ExamTypeDTO} from '../../../../network/models/ExamTypeDTO';

@Component({
  selector: 'app-exam-time',
  templateUrl: './exam-time.component.html',
  styleUrls: ['./exam-time.component.scss']
})
export class ExamTimeComponent implements OnInit {

  examTypes: typeof ExamTypes = ExamTypes;

  selectedExamTypeToRegister: ExamTypes;

  examTimesAndPlaces: ExamCalendar[];
  examLocations: ExamCalendar[];
  examTimes: ExamCalendar[] = [];
  selectedExamPlace = null;
  selectedExamTime = null;

  idCard: MyDocument = null;
  idCardPicture: any;
  idCardErrorMessage = null;
  addressCard: MyDocument = null;
  addressCardPicture: any;
  addressCardErrorMessage = null;

  canRegisterToExam = true;
  registrationStage: number;
  registrationSuccess = false;

  @Input() myRegistrations: Registration[];
  theoreticalExam: Registration = null;
  practicalExam: Registration = null;
  boatPracticeReservation: Registration = null;
  sailBoatPracticeReservation: Registration = null;
  @Output() myRegistrationsChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() studentCourseId: number;

  @Input() myExamTypes: ExamTypeDTO[];

  @ViewChild('closeModalButton') closeModalButton: ElementRef;

  constructor(private network: NetworkService) { }

  ngOnInit(): void {
    this.selectedExamTypeToRegister = null;
    this.registrationStage = 1;
    this.getMyCurrentDocuments();
    Object.values(ExamTypes).forEach( type => {
      this.getRegisteredExam(type);
    });

    this.network.getTopicHierarchy(this.studentCourseId).subscribe(
    hierarchy => {
      hierarchy.forEach(h => {
        if (h.learnedPercent < 80) {
          this.canRegisterToExam = false;
        }
      });
    });
  }

  getMyCurrentDocuments(): void {
    this.network.getMyDocuments().subscribe(
      documents => {
        documents.forEach(document => {
          if (document.documentType.codeValue === DocumentTypes.IdCard) {
            this.idCard = document;
          } else if (document.documentType.codeValue === DocumentTypes.AddressCard) {
            this.addressCard = document;
          }
        });
      });
  }

  getRegisteredExam(examType: ExamTypes): void {
    let registration = null;
    registration = this.myRegistrations.find(reg => reg.examCalendarDTO.examTypeCodeVDTO.codeValue === examType);
    switch (examType) {
      case ExamTypes.ElmeletiVizsga:
        this.theoreticalExam = registration;
        break;
      case ExamTypes.GyakorlatiVizsga:
        this.practicalExam = registration;
        break;
      case ExamTypes.VitorlasGyakorlatiKepzes:
        this.sailBoatPracticeReservation = registration;
        break;
      case ExamTypes.KisgephajoGyakorlatiKepzes:
        this.boatPracticeReservation = registration;
        break;
    }
  }

  downloadDocuments(): void {
    this.network.getDocumentImage(this.idCard.studentDocumentId).subscribe(
      document => {
        this.createImageFromBlob(document, DocumentTypes.IdCard);
      });

    this.network.getDocumentImage(this.addressCard.studentDocumentId).subscribe(
      document => {
        this.createImageFromBlob(document, DocumentTypes.AddressCard);
      });
  }

  uploadIdCard(event): void {
    const file = event.item(0);
    if (file.size / 100000 > 5) {
      this.idCardErrorMessage = 'Túl nagy fájlméret';
      return;
    }
    if (!file.type.startsWith('image')) {
      this.idCardErrorMessage = 'Nem támogatott fájlformátum';
      return;
    }
    this.idCardErrorMessage = null;
    this.network.postDocument(file, DocumentTypes.IdCard).subscribe(
      () => {
        this.getMyCurrentDocuments();
      }
    );
  }

  deleteIdCard(): void {
    this.idCardErrorMessage = null;
    this.network.deleteDocument(this.idCard.studentDocumentId).subscribe(
      () => {
        this.idCard = null;
      });
  }

  uploadAddressCard(event): void {
    const file = event.item(0);
    if (file.size / 100000 > 5) {
      this.addressCardErrorMessage = 'Túl nagy fájlméret';
      return;
    }
    if (!file.type.startsWith('image')) {
      this.addressCardErrorMessage = 'Nem támogatott fájlformátum';
      return;
    }
    this.addressCardErrorMessage = null;
    this.network.postDocument(file, DocumentTypes.AddressCard).subscribe(
      () => {
        this.getMyCurrentDocuments();
      }
    );
  }

  deleteAddressCard(): void {
    this.addressCardErrorMessage = null;
    this.network.deleteDocument(this.addressCard.studentDocumentId).subscribe(
      () => {
        this.addressCard = null;
      });
  }

  removeSelectedExamParams(): void {
    this.registrationSuccess = false;
    this.idCardErrorMessage = null;
    this.addressCardErrorMessage = null;
    this.selectedExamTypeToRegister = null;
    this.registrationStage = 1;
    this.selectedExamPlace = null;
    this.selectedExamTime = null;
    this.examTimes = [];
    this.examLocations = [];
  }

  uploadSelectedExamParams(): void {
    this.registrationSuccess = false;
    this.network.registerToExam(this.studentCourseId, this.selectedExamTime).subscribe(
      () => {
        this.myRegistrationsChanged.emit();
        this.registrationSuccess = true;
      }, error => {
        this.registrationSuccess = false;
    });
    this.registrationStage = 3;
  }

  endRegistrationFlow(): void {
    this.closeModalButton.nativeElement.click();
    this.removeSelectedExamParams();
  }


  allFieldValid(): boolean {
    if (this.idCard && this.addressCard && this.selectedExamTime && this.selectedExamPlace) {
      return true;
    }
    return false;
  }

  selectExamToRegister(examType: ExamTypes): void {
    this.selectedExamTypeToRegister = examType;
    this.getExamLocationsAndTimes(examType);
  }

  getSelectedAddressText(): string {
    return this.examTimesAndPlaces.find( examTime => examTime.examCalendarId === this.selectedExamTime).locationCodeVDTO.description;
  }

  getSelectedTimeText(): string {
    return this.examTimesAndPlaces.find( examTime => examTime.examCalendarId === this.selectedExamTime).startTime;
  }

  getSelectedIntervalText(): string {
    const exam = this.examTimesAndPlaces.find( examTime => examTime.examCalendarId === this.selectedExamTime);
    return exam.startTime + ' - ' + exam.endTime;
  }

  createImageFromBlob(image: Blob, type: DocumentTypes): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      if (type === DocumentTypes.IdCard) {
        this.idCardPicture = reader.result;
      }
      else if (type === DocumentTypes.AddressCard) {
        this.addressCardPicture = reader.result;
      }
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  checkData(): void {
    this.downloadDocuments();
    this.registrationStage = 2;
  }

  getExamLocationsAndTimes(examType: ExamTypes): void {
    this.network.getExamLocationsAndTimes(this.myExamTypes.find(type => type.codeValue === examType).codeId).subscribe(
      locationsAndTimes => {
        this.examTimesAndPlaces = locationsAndTimes;
        this.getUniqueExamLocationsAndTimes();
      }
    );
  }

  getUniqueExamLocationsAndTimes(): void {
    if (this.examTimesAndPlaces) {
      this.examLocations =
        this.examTimesAndPlaces.filter((value, index, self) =>
          self.findIndex(v => v.locationCodeVDTO.codeValue === value.locationCodeVDTO.codeValue) === index
        );
    } else {
      this.examLocations = [];
    }
  }

  getTimesBasedOnLocation(): void {
    if (!this.selectedExamPlace) {
      return;
    }
    this.selectedExamTime = null;
    this.examTimes = this.examTimesAndPlaces.filter(calendar => calendar.locationCodeVDTO.codeValue === this.selectedExamPlace);
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  getExamName(): string {
    switch (this.selectedExamTypeToRegister ) {
      case ExamTypes.ElmeletiVizsga:
        return 'Elméleti vizsga';
        break;
      case ExamTypes.GyakorlatiVizsga:
        return 'Gyakorlati vizsga';
        break;
      case ExamTypes.VitorlasGyakorlatiKepzes:
        return 'Vitorlás gyakorlati oktatás';
        break;
      case ExamTypes.KisgephajoGyakorlatiKepzes:
        return 'Kisgépajó gyakorlati oktatás';
        break;
    }
    return this.selectedExamTypeToRegister;
  }
}
