<app-navbar *ngIf="myCourses" [topTitle]="'Szia, '+username" pageTitle="Főoldal" onDashboard="true">
</app-navbar>
<div class="parent-container">
  <div class="main-container">
    <div class="grid-container" *ngIf="myCourses && myRegistrations && examTypes && desktopView">
      <tr>
        <td class="first-cell">
          <table class="w-100">
            <tr *ngFor="let course of myCourses; let i = index">
              <td colspan="2">
                <app-practice-dashboard class="app-practice-dashboard-grid-item" [course]="course">
                </app-practice-dashboard>
              </td>
            </tr>
            <tr>
              <td class="statistics-grid-item">
                <app-statistics [studentCourseId]="myCourses[0].studentCourseId">
                </app-statistics>
              </td>
              <td class="exam-time-grid-item">
                <app-exam-time [studentCourseId]="myCourses[0].studentCourseId"
                               [myRegistrations]="myRegistrations"
                               [myExamTypes]="examTypes"
                               (myRegistrationsChanged)="getMyRegistrations()">
                </app-exam-time>
              </td>
            </tr>
          </table>
        </td>
        <td class="rent-and-blog-grid-item">
          <app-rent-and-blog>
          </app-rent-and-blog>
        </td>
      </tr>
    </div>
    <div class="d-lg-none d-md-block d-block" *ngIf="myCourses && myRegistrations && examTypes && !desktopView">
      <div *ngFor="let course of myCourses; let i = index" class="mt-2">
        <app-practice-dashboard [course]="course">
        </app-practice-dashboard>
      </div>
      <div class="mt-2">
        <app-statistics [studentCourseId]="myCourses[0].studentCourseId">
        </app-statistics>
      </div>
      <div class="mt-2">
        <app-exam-time [studentCourseId]="myCourses[0].studentCourseId"
                       [myRegistrations]="myRegistrations"
                       [myExamTypes]="examTypes"
                       (myRegistrationsChanged)="getMyRegistrations()">
        </app-exam-time>
      </div>
      <div class="mt-2">
        <app-rent-and-blog>
        </app-rent-and-blog>
      </div>
    </div>
  </div>
</div>
