import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rent-and-blog',
  templateUrl: './rent-and-blog.component.html',
  styleUrls: ['./rent-and-blog.component.scss']
})
export class RentAndBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goToRentShips(): void {
    window.open('https://vizenjo.hu/hajoink/vitorlasberles/', '_blank');
  }

  goToBlog(): void {
    window.open('https://vizenjo.hu/blog/', '_blank');
  }
}
