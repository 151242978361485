import {Component, Input, OnInit} from '@angular/core';
import {Statistics} from '../../../../network/models/Statistics';
import {NetworkService} from '../../../../network/services/network.service';
import {StudentCourse} from "../../../../network/models/StudentCourse";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  total: number;
  successful: number;
  unSuccessful: number;

  statistics: Statistics[];

  @Input() studentCourseId: number;

  constructor(private network: NetworkService) { }

  ngOnInit(): void {
    this.network.getStatistics(this.studentCourseId).subscribe( statistics => {
      this.statistics = statistics;
      if (this.statistics === []) {
        this.statistics = null;
      } else {
        this.calculateTopStats();
      }
    });
  }

  calculateTopStats(): void {
    this.total = 0;
    this.successful = 0;
    this.unSuccessful = 0;
    this.statistics.forEach( statItem => {
      this.total += statItem.lastThreeTestAverageSuccessRate;
      this.successful += statItem.numOfSuccessfulTests;
      this.unSuccessful += statItem.numOfFailedTests;
    });
    this.total /= this.statistics.length;
  }

}
