<div class="parent-container">
  <div class="main-container">
    <ng-container *ngIf="testExam">
      <div class="exam-container">
        <div class="row">
          <div class="col-3 d-flex align-items-baseline justify-content-start text-left">
            <div class="d-inline-block text-center">
              <circle-progress class="semi-bold-text"
                [percent]="(timeLeft * 100) / 60"
                [title]="getTimeLeftString()"
              ></circle-progress>
              <div class="mt-1 remaining-time-text">
                Hátralévő idő
              </div>
            </div>
          </div>
          <div class="col-6 align-items-center justify-content-center">
            <div class="progress exam-progress">
              <div class="progress-bar exam-progress-bar"
                    [ngStyle]="{ width: (((currentQuestionIndex + 1) / testExam.testExamQuestions.length) * 100)+'%' }"
                    role="progressbar"
                    aria-valuenow="{{ currentQuestionIndex + 1 }}"
                    aria-valuemin="0"
                    aria-valuemax="{{ testExam.testExamQuestions.length }}"></div>
            </div>
            <div class="question-number-text mt-2 text-center">
              {{currentQuestionIndex + 1}}/{{testExam.testExamQuestions.length}}. kérdés
            </div>
            <div class="points-text mt-2 text-center">
              {{ testExam.testExamQuestions[currentQuestionIndex].errorPoints }} pont
            </div>
          </div>
          <div class="col-3 d-flex align-items-baseline justify-content-end">
            <a class="exit-text-link" data-toggle="modal" data-target="#examExitConfirm">
              <img class="mr-2" src="../../../assets/images/x-icon.svg" alt="">
              <span class="exit-text">Kilépés a tesztvizsgából
              </span>
            </a>
          </div>
        </div>

        <div class="row mt-lg-2 mt-md-2 mt-4 question-row mb-4">
          <div class="col-lg-4 d-lg-flex d-md-none d-none justify-content-center align-items-center">
            <ng-container *ngIf="imageToShow">
              <img class="help-image" [src]="imageToShow" alt="help image">
            </ng-container>
          </div>
          <div class="col-lg-8 col-md-12 col-12 text-left mt-lg-3">
            <div class="question-text">
              {{testExam.testExamQuestions[currentQuestionIndex].description}}
            </div>
            <ng-container *ngIf="imageToShow">
              <div class="d-lg-none w-100 text-center mt-4">
                <img class="help-image" [src]="imageToShow" alt="help image">
              </div>
            </ng-container>

            <hr class="line">

            <div class="row mt-4">
              <div class="col text-left">
                <div class="row">
                  <div class="col d-flex align-items-center justify-content-start">
                    <button class="btn mr-4 x50" [ngClass]="getAnswerBoxClass('A')" (click)="this.selectedChoice = 'A'; nextQuestionOrResult();">
                      A
                    </button>
                    <div class="align-items-center text-left answer-text">
                      {{testExam.testExamQuestions[currentQuestionIndex].answers[0].description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-left">
                <div class="row">
                  <div class="col d-flex align-items-center justify-content-start">
                    <button class="btn mr-4 x50" [ngClass]="getAnswerBoxClass('B')" (click)="this.selectedChoice = 'B'; nextQuestionOrResult();">
                      B
                    </button>
                    <div class="align-items-center text-left answer-text">
                      {{testExam.testExamQuestions[currentQuestionIndex].answers[1].description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="testExam.testExamQuestions[currentQuestionIndex].answers[2]" class="row mt-3">
              <div class="col text-left">
                <div class="row">
                  <div class="col d-flex align-items-center justify-content-start">
                    <button class="btn mr-4 x50" [ngClass]="getAnswerBoxClass('C')" (click)="this.selectedChoice = 'C'; nextQuestionOrResult();">
                      C
                    </button>
                    <div class="align-items-center text-left answer-text">
                      {{testExam.testExamQuestions[currentQuestionIndex].answers[2].description}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- ExamExitConfirm Modal -->
<div id="examExitConfirm" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <img class="info-image" src="assets/images/modal-info-icon.svg" alt="" >
      <div class="modal-header text-center">
        <button type="button" class="close" data-dismiss="modal"><img class="close-icon" src="assets/images/modal-close-icon.svg" alt=""></button>
      </div>
      <div class="modal-body text-center">
        <div class="mt-2 modal-text">
          Biztosan kilépsz a tesztvizsgából?
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-outline-big button-width" routerLink="/" data-dismiss="modal">
          Kilépek a tesztvizsgából
        </button>
        <button class="button-primary-big button-width ml-2" data-dismiss="modal">
          Folytatom a tesztvizsgát
        </button>
      </div>
    </div>
  </div>
</div>
