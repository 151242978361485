import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from '../../network/services/auth.service';
import {StudentCourse} from '../../network/models/StudentCourse';
import {NetworkService} from '../../network/services/network.service';
import {Registration} from '../../network/models/Registration';
import {map} from 'rxjs/operators';
import {RegistrationStatuses} from './components/exam-time/enums/RegistrationStatuses';
import {ExamTypeDTO} from '../../network/models/ExamTypeDTO';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  myCourses: StudentCourse[] = null;
  myRegistrations: Registration[] = null;
  examTypes: ExamTypeDTO[] = null;

  username: string;

  constructor(private auth: AuthService, private network: NetworkService, private route: ActivatedRoute, private router: Router) { }

  desktopView = true;

  ngOnInit(): void {
    const token = this.route.snapshot.queryParams.token;
    if (token) {
      this.network.getOAuthToken(token).subscribe(oAuth => {
        this.auth.login(oAuth);
        this.router.navigate(
          ['']
        ).then(() => {
          this.loadUserDataIfLoggedIn();
        });
      });
    } else {
      this.loadUserDataIfLoggedIn();
    }

    if (window.innerWidth <= 768) {
      this.desktopView = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (event.target.innerWidth <= 768) {
      this.desktopView = false;
    } else {
      this.desktopView = true;
    }
  }

  loadUserDataIfLoggedIn(): void {
    this.auth.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        this.router.navigate(['vizenjo']);
      } else {
        this.username = this.auth.getUserName();
        this.network.getMyCourses().subscribe( courses => {
          this.myCourses = courses;
        });

        this.getMyRegistrations();

        this.network.getExamTypes().subscribe( examTypes => {
          this.examTypes = examTypes;
        });
      }
    });
  }

  getMyRegistrations(): void {
    this.network.getMyRegistrations().pipe(
      map(registrations =>
        registrations.filter(registration =>
          (registration.registrationStatusCodeVDTO.codeValue === RegistrationStatuses.AcceptedRegistration)
          || (registration.registrationStatusCodeVDTO.codeValue === RegistrationStatuses.Registered))),
    ).subscribe( registrations => {
      this.myRegistrations = registrations;
    });
  }
}
