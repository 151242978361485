<app-navbar topTitle="Tananyagok" [pageTitle]="courseName">
</app-navbar>
<div class="menu-container w-100 d-lg-none d-md-block d-block">
  <a class="back-link-mobile" routerLink="">
    <img class="mr-2" src="../../../assets/images/arrow-left-icon.svg" alt="">Vissza a főoldalra
  </a>
  <button class="menu-button button-primary float-right"
          (click)="openSideNav()">
    Fejezetek
  </button>
</div>
<div class="parent-container">
  <div class="main-container">
    <div *ngIf="topic" class="edu-material-container">
      <div class="d-lg-block d-md-none d-none">
        <div>
          <a class="back-link" routerLink="">
            <img class="mr-2" src="../../../assets/images/arrow-left-icon.svg" alt="">Vissza a főoldalra</a>
        </div>
        <div class="contents-container">
          <div class="section-container" *ngFor="let subTopic of topic.subtopics">
            {{ subTopic.subtopicName }}
            <div id="{{ 'chapter_' + chapter.chapterId }}"*ngFor="let chapter of subTopic.chapters; let i = index"
                 [ngClass]="getChapterClass(chapter)"
                 (click)="chapterSelect(chapter.chapterId)">
              <div>
                {{ chapter.chapterName }}
              </div>
              <div *ngIf="chapter.questionFl" class="d-flex justify-content-end">
                <div class="progress topic-progress">
                  <div class="progress-bar"
                       role="progressbar"
                       [ngStyle]="{ width: chapter.learnedPercent+'%' }"
                       [ngClass]="chapter.learnedPercent < 40 ? 'red-background' : chapter.learnedPercent < 80 ? 'blue-background' : 'green-background'"
                       aria-valuemin="0"
                       aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-lg-none d-md-block d-block">
        <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" class="closebtn" (click)="closeSideNav()">&times;</a>
          <div class="section-container" *ngFor="let subTopic of topic.subtopics">
            {{ subTopic.subtopicName }}
            <div id="{{ 'chapter_' + chapter.chapterId }}"*ngFor="let chapter of subTopic.chapters; let i = index"
                 [ngClass]="getChapterClass(chapter)"
                 (click)="chapterSelect(chapter.chapterId)">
              <div>
                {{ chapter.chapterName }}
              </div>
              <div *ngIf="chapter.questionFl" class="d-flex justify-content-end">
                <div class="progress topic-progress">
                  <div class="progress-bar"
                       role="progressbar"
                       [ngStyle]="{ width: chapter.learnedPercent+'%' }"
                       [ngClass]="chapter.learnedPercent < 40 ? 'red-background' : chapter.learnedPercent < 80 ? 'blue-background' : 'green-background'"
                       aria-valuemin="0"
                       aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters mt-4 mt-md-0 mt-0 mb-2 text-container" (click)="closeSideNav();">
        <div class="col">
          <ng-container *ngIf="selectedChapter">
            <div *ngIf="selectedChapter.textBody; else elseBlock" [innerHTML]="selectedChapter.textBody | noSanitize"></div>
            <ng-template #elseBlock>
              <pdf-viewer class="pdf-viewer"
              [src]="pdfSrc"
              [render-text]="true"
              [original-size]="false"
              ></pdf-viewer>
            </ng-template>
            <div class="d-flex justify-content-center">
              <div class="check-your-knowledge-container">
                <img class="check-your-knowledge-icon" src="assets/images/check-your-knowledge-icon.svg" alt="check your knowledge icon">
                <img class="image check-your-knowledge-container-background" src="assets/images/check-your-knowledge-background.png" alt="check your knowledge">
                <div class="check-your-knowledge-container-content w-100 text-center">
                  <ng-container *ngIf="selectedChapterProgress !== 100 else learnedChapter">
                    <ng-container *ngIf="selectedChapter.questionFl else noQuestions">
                      <div class="check-your-knowledge-text">
                        Megtanultad az alfejeztet? Teszteld tudásod!
                      </div>
                      <button class="button-primary mt-4"
                              routerLink="/practice"
                              [queryParams]="{ studentCourseId: studentCourseId, topicId: topic.topicId, chapterId: selectedChapter.chapterId }">
                        Gyakorlás indítása
                      </button>
                    </ng-container>
                    <ng-template #noQuestions>
                      <div>
                        <div class="check-your-knowledge-text">
                          Az alfejezethez nem tartoznak kérdések!
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-template #learnedChapter>
                    <div class="check-your-knowledge-text">
                      Ezt az alfejezetet már sikeresen megtanultad!
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
