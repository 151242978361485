import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'customDatepipe' })
export class CustomDatePipe implements PipeTransform {
  transform(date: Date | string ): string {
    date = new Date(date);
    let format = 'yyyy-MM-dd HH:mm';
    if (date.getHours() === 0 && date.getMinutes() === 0) {
      format = 'yyyy-MM-dd';
    }
    return new DatePipe('en-US').transform(date, format);
  }
}
