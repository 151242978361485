<div class="card main-container">
  <div class="card-body">
    <div class="card-title title-text text-left">{{ course.courseDTO.courseName }}</div>
    <ng-container *ngIf="topics">
      <div *ngFor="let topic of topics; let i = index;">
        <div class="row pt-1 pb-1">
          <div class="col-lg-4 col-md-6 col-6 d-flex align-items-center">
            <span class="row-image-container mr-2">
              <img class="row-image" [src]="getCoursePictogram(topic.topicName)" alt="">
            </span>
            <span class="list-item-text text-left">
              {{ topic.topicName }}
            </span>
          </div>
          <div class="col-lg-2 col-md-3 col-3 blue-left-border d-flex align-items-center justify-content-center">
            <span class="sections-text">
              Fejezetek:
            </span>
            <span class="list-item-text ml-2">
              {{ topic.subtopics.length }}
            </span>
          </div>
          <div class="col-lg-2 col-md-3 col-3 blue-left-border d-flex align-items-center justify-content-center">
            <div class="w-100">
              <div class="sections-text text-left">
                Haladás:
              </div>
              <div class="progress topic-progress mt-2">
                <div class="progress-bar"
                     role="progressbar"
                     [ngStyle]="{ width: topic.learnedPercent+'%' }"
                     [ngClass]="topic.learnedPercent < 40 ? 'red-background' : topic.learnedPercent < 80 ? 'blue-background' : 'green-background'"
                     aria-valuemin="0"
                     aria-valuemax="100">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-12 mt-lg-0 mt-md-2 mt-2 d-flex align-items-center justify-content-end">
            <ng-container *ngIf="course.courseDTO.practiceOnlyFl else practiceBought">
              <button class="button-outline disabled-opacity mr-3"
                      data-toggle="modal"
                      data-target="#notBoughtModule">
                Tananyag
              </button>
            </ng-container>
            <ng-template #practiceBought>
              <button class="button-outline mr-3"
                      routerLink="/edu-material"
                      [queryParams]="{ studentCourseId: course.studentCourseId, topicId: topic.topicId }"
                      [ngClass]="course.courseDTO.practiceOnlyFl ? 'disabled-opacity' : ''"
                      [disabled]="course.courseDTO.practiceOnlyFl">
                Tananyag
              </button>
            </ng-template>
            <button *ngIf="topic.hasPractice"
                    class="button-primary"
                    routerLink="/exam-selector"
                    [queryParams]="{ studentCourseId: course.studentCourseId, topicId: topic.topicId }">
              Gyakorlás indítása
            </button>
          </div>
        </div>
        <ng-container *ngIf="i !== topics.length - 1">
          <hr class="line">
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="bottom-container">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12 d-flex align-items-center">
          <span class="row-image-container-white mr-3">
             <img class="row-image" src="../../../../../assets/images/exam-simulation.png" alt="">
          </span>
          <div class="text-left">
            <div class="title-text text-left d-inline-block mb-2">
              Tesztvizsga
            </div>
            <br>
            <div class="exam-simulation-description-text d-inline-block">
              A Tesztvizsga segítségével megtapasztalhatod milyen is egy éles vizsgaszituáció.
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 mt-lg-0 mt-md-2 mt-2 d-flex align-items-center justify-content-end">
          <button class="button-primary-big" data-toggle="modal" data-target="#examSelect">
            Elkezdem
            <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NotBoughtModule Modal -->
<div id="notBoughtModule" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <img class="info-image" src="assets/images/modal-info-icon.svg" alt="" >
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><img class="close-icon" src="assets/images/modal-close-icon.svg" alt=""></button>
      </div>
      <div class="modal-body modal-text">
        <p>Nem rendelkezel a <span class="modal-text-blue">“Hajózási ismeretek - vitorlás kishajó”</span> tananyagával, a tananyag megvásárlásához látogass el a vizenjó hivatalos weboldalára!</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-primary-big" data-dismiss="modal">
          Tovább a weboldalra
          <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
        </button>
      </div>
    </div>
  </div>
</div>

<!-- ExamSelect Modal -->
<div id="examSelect" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header text-center d-block">
        <button type="button" class="close close-with-center-text" data-dismiss="modal" (click)="selectExamType(null)">
          <img class="close-icon" src="assets/images/modal-close-icon.svg" alt="">
        </button>
        <span class="modal-title title-text">
          Választható modulok
        </span>
      </div>
      <div class="modal-body modal-text">
        <div class="row">
          <div *ngFor="let topic of filteredTopicList" class="col d-flex justify-content-center align-items-start">
            <div (click)="selectExamType(topic.topicId)" class="cursor-pointer">
              <div class="text-center">
                <div class="d-inline-block"
                    [ngClass]="topic.topicId === selectedExamId ? 'row-image-container-blue' : 'row-image-container-white'">
                  <img class="row-image"
                      [src]="topic.topicId === selectedExamId ? getCoursePictogramWhite(topic.topicName) : getCoursePictogram(topic.topicName)"
                      alt="conditions">
                </div>
              </div>
              <div class="text-center mt-2">
                {{ topic.topicName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-primary-big"
                routerLink="/test-exam"
                [queryParams]="{ studentCourseId: course.studentCourseId, topicId: selectedExamId }"
                data-dismiss="modal"
                [disabled]="selectedExamId === null"
                (click)="selectExamType(null)"
                [ngClass]="selectedExamId === null ? 'disabled-opacity' : ''">
          Tesztvizsga indítása
          <img class="ml-2" src="assets/images/button-arrow-icon.png" alt="">
        </button>
      </div>
    </div>
  </div>
</div>
