<div class="parent-container">
  <div class="main-container">
    <ng-container *ngIf="chapterProgress !== null">
      <ng-container *ngIf="practiceQuestion && chapterProgress < 100">
        <div class="exam-container">
          <div class="row">
            <div class="col-3 d-flex align-items-baseline justify-content-start text-left">
              <a *ngIf="!practiceOnly" class="exit-text-link" data-toggle="modal" data-target="#examBackToEdu">
                <img class="mr-2" src="../../../assets/images/arrow-left-icon.svg" alt="">
                <span class="exit-text">Vissza a tananyaghoz
                    </span>
              </a>
            </div>
            <div class="col-6 align-items-center justify-content-center">
              <div class="progress exam-progress">
                <div class="progress-bar exam-progress-bar"
                     [ngStyle]="{ width: this.chapterProgress+'%' }"
                     role="progressbar"
                     aria-valuenow="{{ chapterProgress }}"
                     aria-valuemin="0"
                     aria-valuemax="100"></div>
              </div>
              <div class="question-number-text mt-2 text-center">
                {{ chapterProgress }}%
              </div>
              <div class="points-text mt-2 text-center">
                {{ practiceQuestion.errorPoints }} pont
              </div>
            </div>
            <div class="col-3 d-flex align-items-baseline justify-content-end">
              <div>
                <div class="text-right">
                  <a class="exit-text-link" data-toggle="modal" data-target="#examExitConfirm">
                    <img class="mr-2" src="../../../assets/images/x-icon.svg" alt="">
                    <span class="exit-text">Vissza a főoldalra
                    </span>
                  </a>
                </div>
                <div class="mt-2 text-right">
                  <button class="button-outline" data-toggle="modal" data-target="#feedbackModal">
                    Hibás kérdés!
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-xl-3 mt-lg-2 mt-md-2 mt-4 question-row">
            <div class="col-lg-4 d-lg-flex d-md-none d-none justify-content-center align-items-center">
              <ng-container *ngIf="imageToShow">
                <img class="help-image" [src]="imageToShow" alt="help image">
              </ng-container>
            </div>
            <div class="col-lg-8 col-md-12 col-12 text-left mt-lg-3">
              <div class="question-text">
                {{practiceQuestion.description}}
              </div>
              <ng-container *ngIf="imageToShow">
                <div class="d-lg-none w-100 text-center mt-4">
                  <img class="help-image" [src]="imageToShow" alt="help image">
                </div>
              </ng-container>

              <hr class="line">

              <div class="row mt-4">
                <div class="col-lg-8 col-md-12 col-12 text-left">
                  <div class="row">
                    <div class="col d-flex align-items-center justify-content-start">
                      <button class="btn mr-4 x50"
                              [ngClass]="getAnswerBoxClass('A')"
                              (click)="this.selectedChoice = 'A'; isCheckMode = true; checkAnswer();"
                              [disabled]="isCheckMode">
                        A
                      </button>
                      <div class="col d-flex align-items-center text-left answer-text">
                        {{practiceQuestion.answers[0].description}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-12 d-flex text-left align-items-center"
                     *ngIf="isCheckMode">
                  <ng-container [ngSwitch]="getAnswerBoxClass('A')">
                    <div *ngSwitchCase="'radio-image-container-correct-answer'" class="blue-left-border">
                      <img src="../../../assets/images/correct.svg" alt="" class="ml-2 answer-check-icon">
                      <span class="correct-text ml-2">
                      Helyes válasz!
                    </span>
                    </div>
                    <div *ngSwitchCase="'radio-image-container-correct-answer-not-selected'" class="blue-left-border">
                    <span class="correct-text-not-selected ml-2">
                      Helyes válasz
                    </span>
                    </div>
                    <div *ngSwitchCase="'radio-image-container-incorrect-answer'" class="blue-left-border">
                      <img src="../../../assets/images/incorrect.svg" alt="" class="ml-2 answer-check-icon">
                      <span class="incorrect-text ml-2">
                      Helytelen válasz!
                    </span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-lg-8 col-md-12 col-12 text-left">
                  <div class="row">
                    <div class="col d-flex align-items-center justify-content-start">
                      <button class="btn mr-4 x50"
                              [ngClass]="getAnswerBoxClass('B')"
                              (click)="this.selectedChoice = 'B'; isCheckMode = true; checkAnswer();"
                              [disabled]="isCheckMode">
                        B
                      </button>
                      <div class="col d-flex align-items-center text-left answer-text">
                        {{practiceQuestion.answers[1].description}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-12 d-flex text-left align-items-center"
                     *ngIf="isCheckMode">
                  <ng-container [ngSwitch]="getAnswerBoxClass('B')">
                    <div *ngSwitchCase="'radio-image-container-correct-answer'" class="blue-left-border">
                      <img src="../../../assets/images/correct.svg" alt="" class="ml-2 answer-check-icon">
                      <span class="correct-text ml-2">
                      Helyes válasz!
                    </span>
                    </div>
                    <div *ngSwitchCase="'radio-image-container-correct-answer-not-selected'" class="blue-left-border">
                    <span class="correct-text-not-selected ml-2">
                      Helyes válasz
                    </span>
                    </div>
                    <div *ngSwitchCase="'radio-image-container-incorrect-answer'" class="blue-left-border">
                      <img src="../../../assets/images/incorrect.svg" alt="" class="ml-2 answer-check-icon">
                      <span class="incorrect-text ml-2">
                      Helytelen válasz!
                    </span>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="practiceQuestion.answers[2]" class="row mt-3">
                <div class="col-lg-8 col-md-12 col-12 text-left">
                  <div class="row">
                    <div class="col d-flex align-items-center justify-content-start">
                      <button class="btn mr-4 x50"
                              [ngClass]="getAnswerBoxClass('C')"
                              (click)="this.selectedChoice = 'C'; isCheckMode = true; checkAnswer();"
                              [disabled]="isCheckMode">
                        C
                      </button>
                      <div class="col d-flex align-items-center text-left answer-text">
                        {{practiceQuestion.answers[2].description}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 col-12 d-flex text-left align-items-center"
                     *ngIf="isCheckMode">
                  <ng-container [ngSwitch]="getAnswerBoxClass('C')">
                    <div *ngSwitchCase="'radio-image-container-correct-answer'" class="blue-left-border">
                      <img src="../../../assets/images/correct.svg" alt="" class="ml-2 answer-check-icon">
                      <span class="correct-text ml-2">
                      Helyes válasz!
                    </span>
                    </div>
                    <div *ngSwitchCase="'radio-image-container-correct-answer-not-selected'" class="blue-left-border">
                    <span class="correct-text-not-selected ml-2">
                      Helyes válasz
                    </span>
                    </div>
                    <div *ngSwitchCase="'radio-image-container-incorrect-answer'" class="blue-left-border">
                      <img src="../../../assets/images/incorrect.svg" alt="" class="ml-2 answer-check-icon">
                      <span class="incorrect-text ml-2">
                      Helytelen válasz!
                    </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="d-lg-none d-md-block d-block mt-4" *ngIf="isCheckMode && selectedHint">
            <div class="text-left">
              Magyarázat: {{ selectedHint }}
            </div>
          </div>
          <hr class="line mt-4">
          <div class="mt-4 mb-5 text-center">
            <button class="button-primary-big"
                    (click)="getChapterProgressAndNextQuestion()"
                    [disabled]="selectedChoice === null"
                    [ngClass]="selectedChoice === null ? 'disabled-opacity' : ''">
              Tovább
            </button>
          </div>
          <div class="d-lg-block d-md-none d-none" *ngIf="isCheckMode && selectedHint" style="position: absolute; bottom: 0px; right: 0; margin: 1vw;">
            <div class="d-flex justify-content-end align-items-end">
              <div class="help-rectangle">
                <div class="text-left">
                  <img src="assets/images/information-icon.svg" alt="information">
                  <span class="help-text-title ml-2" >
                        Magyarázat
                      </span>
                </div>
                <div class="text-left mt-2">
                  {{ selectedHint }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="chapterProgress >= 100">
        <div class="exam-container">
          <div class="row">
            <div class="col-3 d-flex align-items-baseline justify-content-start text-left">
            </div>
            <div class="col-6 align-items-center justify-content-center">
              <div class="progress exam-progress">
                <div class="progress-bar exam-progress-bar"
                     [ngStyle]="{ width: this.chapterProgress+'%' }"
                     role="progressbar"
                     aria-valuenow="'10'"
                     aria-valuemin="0"
                     aria-valuemax="100"></div>
              </div>
              <div class="question-number-text mt-2 text-center">
                {{ chapterProgress }}%
              </div>
            </div>
            <div class="col-3 d-flex align-items-baseline justify-content-end">
            </div>
          </div>
          <div class="mt-lg-2 question-row d-flex align-items-center justify-content-center">
            <div>
              <div>
                <img src="assets/images/result-success-icon.svg" alt="completed chapter">
              </div>
              <div class="success-text mt-4">
                Sikeresen megtanultad a fejezetet!
              </div>
            </div>
          </div>
          <hr class="line mt-4">
          <div class="mt-4 text-center">
            <button *ngIf="!practiceOnly"
                    class="button-outline-big"
                    data-toggle="modal" data-target="#examBackToEdu">
              Vissza a tananyaghoz
            </button>
            <button class="button-primary-big ml-lg-2 ml-md-2 ml-0 mt-lg-0 mt-md-0 mt-2" routerLink="/">
              Vissza a főoldalra
            </button>
          </div>
          <div style="position: absolute; bottom: 0px; left: 0; margin: 1vw;">
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- ExamExitConfirm Modal -->
<div id="examExitConfirm" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <img class="info-image" src="assets/images/modal-info-icon.svg" alt="" >
      <div class="modal-header text-center">
        <button type="button" class="close" data-dismiss="modal"><img class="close-icon" src="assets/images/modal-close-icon.svg" alt=""></button>
      </div>
      <div class="modal-body text-center">
        <div class="mt-2 modal-text">
          Biztosan kilépsz a gyakorlásból?
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-outline-big" routerLink="/" data-dismiss="modal">
          Kilépek a gyakorlásból
        </button>
        <button class="button-primary-big ml-2" data-dismiss="modal">
          Folytatom a gyakorlást
        </button>
      </div>
    </div>
  </div>
</div>

<!-- examBackToEdu Modal -->
<div id="examBackToEdu" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <img class="info-image" src="assets/images/modal-info-icon.svg" alt="" >
      <div class="modal-header text-center">
        <button type="button" class="close" data-dismiss="modal"><img class="close-icon" src="assets/images/modal-close-icon.svg" alt=""></button>
      </div>
      <div class="modal-body text-center">
        <div class="mt-2 modal-text">
          Biztosan visszamész a kapcsolódó tananyagoz?
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-outline-big"
                routerLink="/edu-material"
                [queryParams]="{ studentCourseId: this.studentCourseId, topicId: this.topicId, selectedChapter: this.chapterId }"
                data-dismiss="modal">
          Visszamegyek
        </button>
        <button class="button-primary-big ml-2" data-dismiss="modal">
          Folytatom a gyakorlást
        </button>
      </div>
    </div>
  </div>
</div>

<div id="feedbackModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <app-feedback-modal *ngIf="practiceQuestion" class="modal-content" [feedbackCategory]="'Gyakorlókérdés'" [questionId]="practiceQuestion.questionId"></app-feedback-modal>
  </div>
</div>
