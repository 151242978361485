import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {from, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.addBearerToken(request, next));
  }

  private async addBearerToken(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    if (!request.url.toString().startsWith(environment.basePublicUrl)) {
      await this.auth.isLoggedIn();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`
        }
      });
    }
    return next.handle(request).toPromise();
  }
}
