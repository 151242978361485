import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import {JwtToken} from '../models/JwtToken';
import {DatePipe} from '@angular/common';
import {OAuth} from '../models/OAuth';
import {NetworkService} from './network.service';
import {JwtRefreshToken} from '../models/JwtRefreshToken';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private datePipe: DatePipe, private network: NetworkService) {
  }

  login(oAuth: OAuth): void {
    this.saveOAuthToLocalStorage(oAuth);
  }

  logout(): void {
    localStorage.removeItem('vizenjo_access_token');
    localStorage.removeItem('vizenjo_access_expires_at');
    localStorage.removeItem('vizenjo_refresh_token');
    localStorage.removeItem('vizenjo_refresh_expires_at');
  }

  saveOAuthToLocalStorage(oAuth: OAuth): void {
    localStorage.setItem('vizenjo_access_token', oAuth.access_token);
    localStorage.setItem('vizenjo_access_expires_at', (jwt_decode(oAuth.access_token) as JwtToken).exp.toString());
    localStorage.setItem('vizenjo_refresh_token', oAuth.refresh_token);
    localStorage.setItem('vizenjo_refresh_expires_at', (jwt_decode(oAuth.refresh_token) as JwtRefreshToken).exp.toString());
  }

  async isLoggedIn(): Promise<boolean> {
    if (!this.getExpiration()) {
      this.logout();
      return false;
    }
    let tokenDate = new Date(Number(this.getExpiration()) * 1000);
    let currentDate = new Date();
    let isBeforeExp = ((tokenDate.getTime() - currentDate.getTime()) / 1000) >= 30;
    if (!isBeforeExp) {
      await this.refreshAccessToken().then(() => {
        if (!this.getExpiration()) {
          this.logout();
          return false;
        }
        tokenDate = new Date(Number(this.getExpiration()) * 1000);
        currentDate = new Date();
        isBeforeExp = currentDate < tokenDate;
      });
    }
    if (this.getToken() && isBeforeExp) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  async refreshAccessToken(): Promise<any> {
    if (!this.getRefreshExpiration()) {
      return;
    }
    const refreshTokenDate = new Date(Number(this.getRefreshExpiration()) * 1000);
    const currentDate = new Date();
    const isBeforeExp = currentDate < refreshTokenDate;
    const refreshToken = this.getRefreshToken();
    if (refreshToken && isBeforeExp) {
      await this.network.getRefreshToken(refreshToken).toPromise().then( (oAuth) => {
        this.saveOAuthToLocalStorage(oAuth);
      });
    }
  }

  getToken(): string {
    return localStorage.getItem('vizenjo_access_token');
  }

  getRefreshToken(): string {
    return localStorage.getItem('vizenjo_refresh_token');
  }

  getUserName(): string {
    return (jwt_decode(this.getToken()) as JwtToken).name;
  }

  getExpiration(): string {
    return localStorage.getItem('vizenjo_access_expires_at');
  }

  getRefreshExpiration(): string {
    return localStorage.getItem('vizenjo_refresh_expires_at');
  }
}
