import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkService} from '../../network/services/network.service';
import {TestExam} from '../../network/models/TestExam';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {
  timeLeft = 60;
  interval;

  selectedChoice = null;
  selectedAnswers: { questionId: number, answerId: number }[];

  imageToShow: any;

  testExam: TestExam;

  currentQuestionIndex = 0;

  studentCourseId: number;
  topicId: number;

  constructor(private router: Router,
              private network: NetworkService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.selectedAnswers = [];
    this.studentCourseId = +this.route.snapshot.queryParams.studentCourseId;
    this.topicId = +this.route.snapshot.queryParams.topicId;
    this.currentQuestionIndex = 0;

    if (!this.topicId || !this.studentCourseId) {
      this.router.navigate(['/']);
    } else {
      this.network.getTestExamQuestions(this.studentCourseId, this.topicId).subscribe(
        testExam => {
          this.testExam = testExam;
          this.startTimer();
          if (testExam.testExamQuestions[0].picture) {
            this.network.getImageResource(testExam.testExamQuestions[0].picture.resourceId).subscribe(
              image => {
                this.createImageFromBlob(image);
              }
            );
          }
        },
        () => {
          this.router.navigate(['/']);
        }
      );
    }
  }

  nextQuestionOrResult(): void {
    setTimeout(() => {
        this.selectedAnswers.push({questionId: this.getQuestionId(), answerId: this.getAnswerId()});
        this.selectedChoice = null;
        if (this.currentQuestionIndex >= this.testExam.testExamQuestions.length - 1) {
          clearInterval(this.interval);
          this.network.postTestExamAnswers(this.testExam.testExamId, this.selectedAnswers).subscribe(
            (testExamSummary) => {
              this.router.navigate(['/result'], {
                state: { testExamSummary },
                queryParams: { studentCourseId: this.studentCourseId, topicId: this.topicId }});
            },
            error => {
              this.router.navigate(['/']);
            }
          );
        } else {
          this.imageToShow = null;
          clearInterval(this.interval);
          if (this.currentQuestionIndex < this.testExam.testExamQuestions.length) {
            this.currentQuestionIndex++;
            this.timeLeft = 60;
            this.startTimer();
            if (this.testExam.testExamQuestions[this.currentQuestionIndex].picture) {
              this.network.getImageResource(this.testExam.testExamQuestions[this.currentQuestionIndex].picture.resourceId).subscribe(
                image => {
                  this.createImageFromBlob(image);
                }
              );
            }
          }
        }
    },
      300);
  }

  startTimer(): void {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.nextQuestionOrResult();
      }
    }, 1000);
  }

  pauseTimer(): void {
    clearInterval(this.interval);
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getTimeLeftString(): string {
    let time = '00:';
    if (this.timeLeft < 10) {
      time += '0';
    }
    time += this.timeLeft;
    return time;
  }

  getAnswerBoxClass(boxLetter: string): string {
    if (boxLetter === this.selectedChoice) {
      return 'radio-image-container-selected';
    } else {
      return 'radio-image-container-not-selected';
    }
  }

  getAnswerId(): number {
    if (!this.selectedChoice) {
      return null;
    } else {
      const selectedIndex = this.selectedChoice === 'A' ? 0 : this.selectedChoice === 'B' ? 1 : 2;
      return this.testExam.testExamQuestions[this.currentQuestionIndex].answers[selectedIndex].answerId;
    }
  }

  getQuestionId(): number {
    return this.testExam.testExamQuestions[this.currentQuestionIndex].questionId;
  }
}
