import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {PracticeQuestion} from '../../network/models/PracticeQuestion';
import {ActivatedRoute, Router} from '@angular/router';
import {NetworkService} from '../../network/services/network.service';

@Component({
  selector: 'app-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class PracticeComponent implements OnInit, AfterContentChecked {

  topicName: string;
  chapterName: string;

  isCheckMode = false;

  selectedChoice = null;
  selectedHint = null;

  imageToShow: any;

  practiceQuestion: PracticeQuestion;

  studentCourseId: number;
  chapterId: number;
  topicId: number;
  chapterProgress: number;
  practiceOnly = true;

  constructor(private router: Router,
              private network: NetworkService,
              private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.studentCourseId = +this.route.snapshot.queryParams.studentCourseId;
    this.chapterId = +this.route.snapshot.queryParams.chapterId;
    this.topicId = +this.route.snapshot.queryParams.topicId;

    this.network.getMyCourses().subscribe(courses => {
      const myCourse = courses.find(course => course.courseDTO.topicIds.includes(this.topicId));
      this.practiceOnly = myCourse.courseDTO.practiceOnlyFl;
    });

    this.network.getTopicHierarchy(this.studentCourseId).subscribe( hierarchy => {
      const selectedTopic = hierarchy.find(topic => topic.topicId === this.topicId);
      if (selectedTopic) {
        this.topicName = selectedTopic.topicName;
      }
    });

    this.network.getChapter(this.chapterId).subscribe( chapter => {
      this.chapterName = chapter.chapterName;
    });

    this.getChapterProgressAndNextQuestion();
  }

  getNextPracticeQuestion(): void {
    this.network.getPracticeQuestions(this.studentCourseId, this.chapterId).subscribe(
      practiceQuestion => {
        if (practiceQuestion) {
          this.practiceQuestion = practiceQuestion;
          if (practiceQuestion.picture) {
            this.network.getImageResource(practiceQuestion.picture.resourceId).subscribe(
              image => {
                this.createImageFromBlob(image);
              }
            );
          }
        }
      }
    );
  }

  getChapterProgressAndNextQuestion(): void {
    this.isCheckMode = false;
    this.selectedChoice = null;
    this.imageToShow = null;
    this.selectedHint = null;
    this.chapterProgress = null;
    this.practiceQuestion = null;
    this.network.getChapterStatistics(this.studentCourseId, this.chapterId).subscribe(stats => {
      this.chapterProgress = stats.learnedPercent;
      if (this.chapterProgress < 100) {
        this.getNextPracticeQuestion();
      }
    });
  }

  checkAnswer(): void {
    this.postAnswer();
    this.getAnswerHint();
  }

  postAnswer(): void {
    this.network.postPracticeAnswer(this.studentCourseId, this.getAnswerId()).subscribe();
  }

  getAnswerId(): number {
    if (!this.selectedChoice) {
      return null;
    } else {
      const selectedIndex = this.selectedChoice === 'A' ? 0 : this.selectedChoice === 'B' ? 1 : 2;
      return this.practiceQuestion.answers[selectedIndex].answerId;
    }
  }

  getAnswerHint(): void {
    if (!this.selectedChoice) {
      return null;
    } else {
      const selectedIndex = this.selectedChoice === 'A' ? 0 : this.selectedChoice === 'B' ? 1 : 2;
      this.selectedHint = this.practiceQuestion.answers[selectedIndex].hint;
    }
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getAnswerBoxClass(boxLetter: string): string {
    if (!this.isCheckMode) {
      if (boxLetter === this.selectedChoice) {
        return 'radio-image-container-selected';
      } else {
        return 'radio-image-container-not-selected';
      }
    } else {
      const boxIndex = boxLetter === 'A' ? 0 : boxLetter === 'B' ? 1 : 2;
      const selectedIndex = this.selectedChoice === 'A' ? 0 : this.selectedChoice === 'B' ? 1 : 2;
      let correctAnswerIndex = 0;
      this.practiceQuestion.answers.forEach( (answer, index) => {
        if (answer.correctAnswerFl) {
          correctAnswerIndex = index;
        }
      });
      if (boxIndex === correctAnswerIndex) {
        if (selectedIndex === correctAnswerIndex) {
          return 'radio-image-container-correct-answer';
        } else {
          return 'radio-image-container-correct-answer-not-selected';
        }
      } else if (boxIndex === selectedIndex) {
        return 'radio-image-container-incorrect-answer';
      }
    }
    return 'radio-image-container-not-selected';
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
}
