<div class="main-container">
  <div *ngIf="myRegistrations" id="appointmentCarousel" class="carousel slide" data-ride="carousel" data-interval="false">
    <ol class="carousel-indicators">
      <li *ngFor="let item of arrayOne(4); let i = index"
          data-target="#appointmentCarousel"
          data-slide-to="{{ i }}"
          [ngClass]="i === 0 ? 'active' : ''"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="card card-container-light">
          <div class="card-body d-flex align-items-center justify-content-center">
            <div *ngIf="sailBoatPracticeReservation else notRegisteredSailB">
              <div class="text-center">
                <div class="row-image-container-white d-inline-block">
                  <img class="row-image" src="/assets/images/sailboat-icon.svg" alt="">
                </div>
              </div>
              <div class="exam-group-title-text-black mt-2 text-center">
                {{ sailBoatPracticeReservation.examCalendarDTO.courseDTO.courseName }} -
                <br>{{ sailBoatPracticeReservation.examCalendarDTO.courseDTO.description }}
              </div>
              <div class="module-text-black mt-2 text-center">
                {{ sailBoatPracticeReservation.examCalendarDTO.examTypeCodeVDTO.description }}
              </div>
              <hr class="line mr-4 ml-4 mt-1">
              <div class="pl-4 pr-4">
                <div class="row">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-date.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text-black text-left">
                        {{ sailBoatPracticeReservation.examCalendarDTO.startTime | customDatepipe }} -<br>
                        {{ sailBoatPracticeReservation.examCalendarDTO.endTime | customDatepipe }}
                      </div>
                      <div class="module-text-black text-left">
                        oktatás időpontja
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-location.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text-black text-left">
                        {{ sailBoatPracticeReservation.examCalendarDTO.locationCodeVDTO.description }}
                      </div>
                      <div class="module-text-black text-left">
                        oktatás helyszíne
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #notRegisteredSailB>
              <div>
                <div class="text-center">
                  <div class="row-image-container-white d-inline-block">
                    <img class="row-image" src="/assets/images/sailboat-icon.svg" alt="">
                  </div>
                </div>
                <div class="exam-group-title-text-black mt-2 text-center">
                  Vitorlás gyakorlati oktatás
                </div>
                <div class="module-text-black mt-2 text-center">
                  Gyakorlati oktatás
                </div>
                <hr class="line mr-4 ml-4 mt-1">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="mt-2">
                    <div class="no-appointment-text-black">
                      Még nem jelentkeztél oktatásra
                    </div>
                    <button class="btn button-primary-big-white-wo-border mt-4"
                            data-toggle="modal" data-target="#examSignUp"
                            (click)="selectExamToRegister(examTypes.VitorlasGyakorlatiKepzes)">
                      Jelentkezem
                      <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card-container-light">
          <div class="card-body d-flex align-items-center justify-content-center">
            <div *ngIf="boatPracticeReservation else notRegisteredB">
              <div class="text-center">
                <div class="row-image-container-white d-inline-block">
                  <img class="row-image" src="/assets/images/boat-icon.svg" alt="">
                </div>
              </div>
              <div class="exam-group-title-text-black mt-2 text-center">
                {{ boatPracticeReservation.examCalendarDTO.courseDTO.courseName }} -<br>{{ boatPracticeReservation.examCalendarDTO.courseDTO.description }}
              </div>
              <div class="module-text-black mt-2 text-center">
                {{ boatPracticeReservation.examCalendarDTO.examTypeCodeVDTO.description }}
              </div>
              <hr class="line mr-4 ml-4 mt-1">
              <div class="pl-4 pr-4">
                <div class="row">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-date.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text-black text-left">
                        {{ boatPracticeReservation.examCalendarDTO.startTime | customDatepipe }} -<br>
                        {{ boatPracticeReservation.examCalendarDTO.endTime | customDatepipe }}
                      </div>
                      <div class="module-text-black text-left">
                        oktatás időpontja
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-location.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-title-text-black text-left">
                        {{ boatPracticeReservation.examCalendarDTO.locationCodeVDTO.description }}
                      </div>
                      <div class="module-text-black text-left">
                        oktatás helyszíne
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #notRegisteredB>
              <div>
                <div class="text-center">
                  <div class="row-image-container-white d-inline-block">
                    <img class="row-image" src="/assets/images/boat-icon.svg" alt="">
                  </div>
                </div>
                <div class="exam-group-title-text-black mt-2 text-center">
                  Kisgéphajó gyakorlati oktatás
                </div>
                <div class="module-text-black mt-2 text-center">
                  Gyakorlati oktatás
                </div>
                <hr class="line mr-4 ml-4 mt-1">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="mt-2">
                    <div class="no-appointment-text-black">
                      Még nem jelentkeztél oktatásra
                    </div>
                    <button class="btn button-primary-big-white-wo-border mt-4"
                            data-toggle="modal" data-target="#examSignUp"
                            (click)="selectExamToRegister(examTypes.KisgephajoGyakorlatiKepzes)">
                      Jelentkezem
                      <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card-container">
          <div class="card-body d-flex align-items-center justify-content-center">
            <div *ngIf="theoreticalExam else notRegisteredTheo">
              <div class="text-center">
                <div class="row-image-container-white d-inline-block">
                  <img class="row-image" src="/assets/images/conditions-with-gear-icon.svg" alt="">
                </div>
              </div>
              <div class="exam-group-title-text mt-2 text-center">
                {{ theoreticalExam.examCalendarDTO.courseDTO.courseName }} -<br>{{ theoreticalExam.examCalendarDTO.courseDTO.description }}
              </div>
              <div class="module-text mt-2 text-center">
                {{ theoreticalExam.examCalendarDTO.examTypeCodeVDTO.description }}
              </div>
              <hr class="line mr-4 ml-4 mt-1">
              <div class="pl-4 pr-4">
                <div class="row">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-date.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text text-left">
                        {{ theoreticalExam.examCalendarDTO.startTime | customDatepipe }}
                      </div>
                      <div class="module-text text-left">
                        vizsga időpontja
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-location.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text text-left">
                        {{ theoreticalExam.examCalendarDTO.locationCodeVDTO.description }}
                      </div>
                      <div class="module-text text-left">
                        vizsga helyszíne
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #notRegisteredTheo>
              <div>
                <div class="text-center">
                  <div class="row-image-container-white d-inline-block">
                    <img class="row-image" src="/assets/images/conditions-with-gear-icon.svg" alt="">
                  </div>
                </div>
                <div class="exam-group-title-text mt-2 text-center">
                  Komplex Elméleti Vizsga
                </div>
                <div class="module-text mt-2 text-center">
                  Összetett vizsga
                </div>
                <hr class="line mr-4 ml-4 mt-1">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="mt-2">
                    <div class="no-appointment-text">
                      Még nem jelentkeztél vizsgára
                    </div>
                    <ng-container *ngIf="canRegisterToExam else denyExamRegistration">
                      <button class="btn button-primary-big-white mt-4"
                              data-toggle="modal" data-target="#examSignUp"
                              (click)="selectExamToRegister(examTypes.ElmeletiVizsga)">
                        Jelentkezem
                        <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
                      </button>
                    </ng-container>
                    <ng-template #denyExamRegistration>
                      <div class="mt-4 module-text text-center">
                        Teljesítsd az összes fejezetet minimum 80%-ra a vizsgajelentkezéshez!
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="card card-container">
          <div class="card-body d-flex align-items-center justify-content-center">
            <div *ngIf="practicalExam else notRegisteredPrac">
              <div class="text-center">
                <div class="row-image-container-white d-inline-block">
                  <img class="row-image" src="/assets/images/sailboat-with-gear-icon.svg" alt="">
                </div>
              </div>
              <div class="exam-group-title-text mt-2 text-center">
                {{ practicalExam.examCalendarDTO.courseDTO.courseName }} -<br>{{ practicalExam.examCalendarDTO.courseDTO.description }}
              </div>
              <div class="module-text mt-2 text-center">
                {{ practicalExam.examCalendarDTO.examTypeCodeVDTO.description }}
              </div>
              <hr class="line mr-4 ml-4 mt-1">
              <div class="pl-4 pr-4">
                <div class="row">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-date.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text text-left">
                        {{ practicalExam.examCalendarDTO.startTime | customDatepipe }}
                      </div>
                      <div class="module-text text-left">
                        vizsga időpontja
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <img src="assets/images/exam-location.svg">
                  </div>
                  <div class="col-9 d-flex justify-content-start align-items-center">
                    <div class="d-inline-block">
                      <div class="exam-group-text text-left">
                        {{ practicalExam.examCalendarDTO.locationCodeVDTO.description }}
                      </div>
                      <div class="module-text text-left">
                        vizsga helyszíne
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #notRegisteredPrac>
              <div>
                <div class="text-center">
                  <div class="row-image-container-white d-inline-block">
                    <img class="row-image" src="/assets/images/sailboat-with-gear-icon.svg" alt="">
                  </div>
                </div>
                <div class="exam-group-title-text mt-2 text-center">
                  Komplex Gyakorlati Vizsga
                </div>
                <div class="module-text mt-2 text-center">
                  Összetett vizsga
                </div>
                <hr class="line mr-4 ml-4 mt-1">
                <div class="d-flex justify-content-center align-items-center">
                  <div class="mt-2">
                    <div class="no-appointment-text">
                      Még nem jelentkeztél vizsgára
                    </div>
                    <ng-container *ngIf="canRegisterToExam else denyExamRegistration">
                      <button class="btn button-primary-big-white mt-4"
                              data-toggle="modal" data-target="#examSignUp"
                              (click)="selectExamToRegister(examTypes.GyakorlatiVizsga)">
                        Jelentkezem
                        <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
                      </button>
                    </ng-container>
                    <ng-template #denyExamRegistration>
                      <div class="mt-4 module-text text-center">
                        Teljesítsd az összes fejezetet minimum 80%-ra a vizsgajelentkezéshez!
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#appointmentCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#appointmentCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>


<!-- ExamSignUp Modal -->
<div id="examSignUp" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header text-center d-block">
        <button #closeModalButton type="button" class="close close-with-center-text" data-dismiss="modal" (click)="removeSelectedExamParams()">
          <img class="close-icon" src="assets/images/modal-close-icon.svg" alt="">
        </button>
        <span class="modal-title title-text">
          Jelentkezés<br>{{ getExamName() }}
        </span>
      </div>
      <div class="modal-body modal-text">
        <div class="modal-id-text">
          Kérjük további információért látogass el honlapunkra: <a href="https://vizenjo.hu/kapcsolat/">Kapcsolat</a>
        </div>
<!--        <ng-container *ngIf="registrationStage !== 3 else endRegistration">-->
<!--          <ng-container *ngIf="registrationStage === 1 else checkModeTitle">-->
<!--           <div class="row">-->
<!--             <div class="col-2 d-flex justify-content-start align-items-center">-->
<!--               <img src="assets/images/id-icon.svg" alt="id">-->
<!--             </div>-->
<!--             <div class="col d-flex justify-content-start align-items-center text-left modal-id-text">-->
<!--                 Kérjük vedd elő a Személyi igazolványodat és készíts képet az elejéről majd a hátuljáról, utána töltsd fel az ürlapon keresztül.-->
<!--             </div>-->
<!--           </div>-->
<!--          </ng-container>-->
<!--          <ng-template #checkModeTitle>-->
<!--            <div class="d-flex justify-content-center align-items-center modal-id-text">-->
<!--              Kérjük ellenőrizd az adataidat!-->
<!--            </div>-->
<!--          </ng-template>-->
<!--          <div class="text-left">-->
<!--            <div class="droparea-title-text">-->
<!--              Személyi igazolvány eleje-->
<!--            </div>-->
<!--            <ng-container *ngIf="registrationStage === 1 else checkModeID">-->
<!--              <ng-container *ngIf="idCard else noIdUploaded">-->
<!--                <div class="droparea droparea-green-background">-->
<!--                  <div>-->
<!--                    <div class="text-center">-->
<!--                      <span class="droparea-drop-text">-->
<!--                      {{ idCard.documentType.description }} - [FELTÖLTVE]-->
<!--                    </span>-->
<!--                      <span class="ml-2 cursor-pointer" (click)="deleteIdCard()">-->
<!--                      <img src="assets/images/delete-file-icon.svg" alt="delete-file">-->
<!--                    </span>-->
<!--                    </div>-->
<!--                    <div class="droparea-footer-text text-center mt-2">-->
<!--                      Maximális engedélyezett fájlméret 5Mb, .jpg, .png, .bmp formátumban-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--              <ng-template #noIdUploaded>-->
<!--                <div class="droparea"-->
<!--                     [ngClass]="idCardErrorMessage ? 'droparea-red-background' : 'droparea-blue-background'"-->
<!--                     (click)="fileInputID.click()"-->
<!--                     appDragDrop-->
<!--                     (fileDropped)="uploadIdCard($event)">-->
<!--                  <div>-->
<!--                    <input hidden type="file" #fileInputID (change)="uploadIdCard($event.target.files)" id="fileInputFront" accept="image/*">-->
<!--                    <div class="droparea-drop-text text-center">-->
<!--                      Húzd ide a fájlt vagy <span class="blue-text">tallózz</span>-->
<!--                    </div>-->
<!--                    <div class="droparea-footer-text text-center mt-2">-->
<!--                      Maximális engedélyezett fájlméret 5Mb, .jpg, .png, .bmp formátumban-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="droparea-error-text" [hidden]="!idCardErrorMessage">-->
<!--                  {{ idCardErrorMessage }}-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </ng-container>-->
<!--            <ng-template #checkModeID>-->
<!--              <div class="mt-2">-->
<!--                <img class="image-container" [src]="idCardPicture" alt="id card">-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--          <div class="mt-4 text-left">-->
<!--            <div class="droparea-title-text">-->
<!--              Lakcímkártya hátulja-->
<!--            </div>-->
<!--            <ng-container *ngIf="registrationStage === 1 else checkModeAddress">-->
<!--              <ng-container *ngIf="addressCard else noAddressUploaded">-->
<!--                <div class="droparea droparea-green-background">-->
<!--                  <div>-->
<!--                    <div class="text-center">-->
<!--                    <span class="droparea-drop-text">-->
<!--                      {{ addressCard.documentType.description }} - [FELTÖLTVE]-->
<!--                    </span>-->
<!--                      <span class="ml-2 cursor-pointer" (click)="deleteAddressCard()">-->
<!--                      <img src="assets/images/delete-file-icon.svg" alt="delete-file">-->
<!--                    </span>-->
<!--                    </div>-->
<!--                    <div class="droparea-footer-text text-center mt-2">-->
<!--                      Maximális engedélyezett fájlméret 5Mb, .jpg, .png, .bmp formátumban-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--              <ng-template #noAddressUploaded>-->
<!--                <div class="droparea"-->
<!--                     [ngClass]="addressCardErrorMessage ? 'droparea-red-background' : 'droparea-blue-background'"-->
<!--                     (click)="fileInputAddress.click()"-->
<!--                     appDragDrop-->
<!--                     (fileDropped)="uploadAddressCard($event)">-->
<!--                  <div>-->
<!--                    <input hidden type="file" #fileInputAddress (change)="uploadAddressCard($event.target.files)" id="fileInputBack" accept="image/*">-->
<!--                    <div class="droparea-drop-text text-center">-->
<!--                      Húzd ide a fájlt vagy <span class="blue-text">tallózz</span>-->
<!--                    </div>-->
<!--                    <div class="droparea-footer-text text-center mt-2">-->
<!--                      Maximális engedélyezett fájlméret 5Mb, .jpg, .png, .bmp formátumban-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="droparea-error-text" [hidden]="!addressCardErrorMessage">-->
<!--                  {{ addressCardErrorMessage }}-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </ng-container>-->
<!--            <ng-template #checkModeAddress>-->
<!--              <div class="mt-2" >-->
<!--                <img class="image-container" [src]="addressCardPicture" alt="address card">-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--          <div class="mt-4 text-left">-->
<!--            <ng-container *ngIf="registrationStage === 1 else checkModeLocation">-->
<!--              <span class="droparea-title-text">-->
<!--                Helyszín választása-->
<!--              </span>-->
<!--              <ng-select class="vizenjo-select" [items]="examLocations"-->
<!--                         placeholder="Válassz helyszínt"-->
<!--                         [(ngModel)]="selectedExamPlace"-->
<!--                         bindLabel="locationCodeVDTO.description"-->
<!--                         bindValue="locationCodeVDTO.codeValue"-->
<!--                         [clearable]="false"-->
<!--                         (click)="getTimesBasedOnLocation()">-->
<!--              </ng-select>-->
<!--            </ng-container>-->
<!--            <ng-template #checkModeLocation>-->
<!--              <div class="droparea-title-text">-->
<!--                Kiválasztott helyszín-->
<!--              </div>-->
<!--              <div class="selectedText mt-2">-->
<!--                {{ getSelectedAddressText() }}-->
<!--              </div>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--          <div class="mt-4 text-left">-->
<!--            <ng-container *ngIf="registrationStage === 1 else checkModeTime">-->
<!--              <ng-container *ngIf="((selectedExamTypeToRegister === examTypes.KisgephajoGyakorlatiKepzes)-->
<!--              || ( selectedExamTypeToRegister === examTypes.VitorlasGyakorlatiKepzes)) else normalSelect">-->
<!--                <span class="droparea-title-text">-->
<!--                  Válassz időintervallumot-->
<!--                </span>-->
<!--                <ng-select class="vizenjo-select" [items]="examTimes"-->
<!--                           placeholder="Válassz időintervallumot"-->
<!--                           [(ngModel)]="selectedExamTime"-->
<!--                           bindValue="examCalendarId"-->
<!--                           bindLabel="startTime"-->
<!--                           [clearable]="false"-->
<!--                           [disabled]="!selectedExamPlace">-->
<!--                  <ng-template ng-label-tmp let-item="item">-->
<!--                    {{ item.startTime | customDatepipe}} - {{ item.endTime | customDatepipe }}-->
<!--                  </ng-template>-->
<!--                  <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">-->
<!--                    {{ item.startTime | customDatepipe}} - {{ item.endTime | customDatepipe }}-->
<!--                  </ng-template>-->
<!--                </ng-select>-->
<!--              </ng-container>-->
<!--              <ng-template #normalSelect>-->
<!--                <div>-->
<!--                  <span class="droparea-title-text">-->
<!--                  Válassz időpontot-->
<!--                </span>-->
<!--                  <ng-select class="vizenjo-select" [items]="examTimes"-->
<!--                             placeholder="Válassz időpontot"-->
<!--                             [(ngModel)]="selectedExamTime"-->
<!--                             bindValue="examCalendarId"-->
<!--                             bindLabel="startTime"-->
<!--                             [clearable]="false" [disabled]="!selectedExamPlace">-->
<!--                    <ng-template ng-label-tmp let-item="item">-->
<!--                      {{ item.startTime | customDatepipe}}-->
<!--                    </ng-template>-->
<!--                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">-->
<!--                      {{ item.startTime | customDatepipe}}-->
<!--                    </ng-template>-->
<!--                  </ng-select>-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </ng-container>-->
<!--            <ng-template #checkModeTime>-->
<!--              <ng-container *ngIf="((selectedExamTypeToRegister === examTypes.KisgephajoGyakorlatiKepzes)-->
<!--              || ( selectedExamTypeToRegister === examTypes.VitorlasGyakorlatiKepzes)) else normalTime">-->
<!--                <div class="droparea-title-text">-->
<!--                  Kiválasztott időintervallum-->
<!--                </div>-->
<!--                <div class="selectedText mt-2">-->
<!--                  {{ getSelectedIntervalText() }}-->
<!--                </div>-->
<!--              </ng-container>-->
<!--              <ng-template #normalTime>-->
<!--                <div>-->
<!--                  <div class="droparea-title-text">-->
<!--                    Kiválasztott időpont-->
<!--                  </div>-->
<!--                  <div class="selectedText mt-2">-->
<!--                    {{ getSelectedTimeText() }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-template>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--        <ng-template #endRegistration>-->
<!--          <div *ngIf="registrationSuccess else registrationFailed">-->
<!--            <div>-->
<!--              <img src="assets/images/result-success-icon.svg" alt="successful registration">-->
<!--            </div>-->
<!--            <div class="success-text text-center">-->
<!--              Sikeres jelentkezés!-->
<!--            </div>-->
<!--            <div class="text-center modal-text mt-2">-->
<!--              Sikeresen jelentkezés: a<br>-->
<!--              <span class="modal-text-blue">“{{getExamName()}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <ng-template #registrationFailed>-->
<!--            <div>-->
<!--              <div>-->
<!--                <img src="assets/images/result-failed-icon.svg" alt="successful registration">-->
<!--              </div>-->
<!--              <div class="failed-text">-->
<!--                Sikertelen jelentkezés!-->
<!--              </div>-->
<!--              <div class="text-center modal-text mt-2">-->
<!--                Sikertelen jelentkezés:<br>-->
<!--                <span class="modal-text-blue">“{{getExamName()}}</span>-->
<!--              </div>-->
<!--              <div class="text-center modal-text mt-2">-->
<!--                Kérjük próbálja meg később!-->
<!--              </div>-->
<!--            </div>-->
<!--          </ng-template>-->
<!--        </ng-template>-->
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-primary-big" data-dismiss="modal">
          Vissza
          <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
        </button>
<!--        <ng-container *ngIf="registrationStage !== 3 else endRegistrationButton">-->
<!--          <ng-container *ngIf="registrationStage === 1 else checkModeButton">-->
<!--            <button class="button-primary-big"-->
<!--                    (click)="checkData()" [disabled]="!allFieldValid()" [ngClass]="allFieldValid() ? '' : 'disabled-opacity'">-->
<!--              Tovább-->
<!--              <img class="ml-2" src="assets/images/button-arrow-icon.png" alt="">-->
<!--            </button>-->
<!--          </ng-container>-->
<!--          <ng-template #checkModeButton>-->
<!--            <button class="button-primary-big-white"-->
<!--                    (click)="registrationStage = 1" [disabled]="!allFieldValid()" [ngClass]="allFieldValid() ? '' : 'disabled-opacity'">-->
<!--              Adatok módosítása-->
<!--            </button>-->
<!--            <button type="button"-->
<!--                    class="button-primary-big"-->
<!--                    data-dismiss="modal"-->
<!--                    (click)="uploadSelectedExamParams()" [disabled]="!allFieldValid()" [ngClass]="allFieldValid() ? '' : 'disabled-opacity'">-->
<!--              Jelentkezés-->
<!--              <img class="ml-2" src="assets/images/button-arrow-icon.png" alt="">-->
<!--            </button>-->
<!--          </ng-template>-->
<!--        </ng-container>-->
<!--        <ng-template #endRegistrationButton>-->
<!--          <div>-->
<!--            <button class="button-primary-big"-->
<!--                    (click)="endRegistrationFlow()">-->
<!--              Rendben-->
<!--            </button>-->
<!--          </div>-->
<!--        </ng-template>-->
      </div>
    </div>
  </div>
</div>
