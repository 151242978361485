import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HierarchyTopic} from '../models/HierarchyTopic';
import {Chapter} from '../models/Chapter';
import {PracticeQuestion} from '../models/PracticeQuestion';
import {TestExam} from '../models/TestExam';
import {TestExamSummary} from '../models/TestExamSummary';
import {Course} from '../models/Course';
import {MyDocument} from '../models/MyDocument';
import {Registration} from '../models/Registration';
import {ExamCalendar} from '../models/ExamCalendar';
import {StudentCourse} from '../models/StudentCourse';
import {Statistics} from '../models/Statistics';
import {environment} from '../../../environments/environment';
import {NotificationService} from '../../notification/notification.service';
import {NotificationType} from '../../notification/NotificationType';
import {ExamTypeDTO} from '../models/ExamTypeDTO';
import {DatePipe} from '@angular/common';
import {ChapterStatistics} from '../models/ChapterStatistics';
import {Feedback} from '../models/Feedback';
import {OAuth} from '../models/OAuth';
import {AuthService} from './auth.service';
import {TopicHasPracticeDTO} from '../models/TopicHasPracticeDTO';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  baseUrl = environment.baseUrl;
  basePublicUrl = environment.basePublicUrl;

  constructor(private http: HttpClient,
              private notificationService: NotificationService,
              private datePipe: DatePipe) {
  }

  getTopicHierarchy(studentCourseId: number): Observable<HierarchyTopic[]> {
    return this
      .http.get<HierarchyTopic[]>(`${this.baseUrl}/hierarchy/${studentCourseId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getChapter(chapterId: number): Observable<Chapter> {
    return this
      .http.get<Chapter>(`${this.baseUrl}/chapters/${chapterId}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getPracticeQuestions(studentCourseId: number, chapterId: number): Observable<PracticeQuestion> {
    return this
      .http.get<PracticeQuestion[]>(
        `${this.baseUrl}/practice_questions/student_course/${studentCourseId}/chapter/${chapterId}`
      )
      .pipe(
        map((practiceQuestions) => practiceQuestions[0]),
        catchError(this.handleError.bind(this))
      );
  }

  postPracticeAnswer(studentCourseId: number, answerId: number): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/practice_questions/student_course/${studentCourseId}/answer/${answerId}`, null
    )
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getTestExamQuestions(studentCourseId: number, topicId): Observable<TestExam> {
    return this
      .http.get<TestExam>(
        `${this.baseUrl}/test-exam/start/${studentCourseId}/${topicId}`
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  postTestExamAnswers(testExamId: number, answers: { questionId: number, answerId: number }[]): Observable<TestExamSummary> {
    return this.http.post<TestExamSummary>(
      `${this.baseUrl}/test-exam/${testExamId}/answers`, answers
    )
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getTestExamHistory(): Observable<TestExamSummary[]> {
    return this
      .http.get<TestExamSummary[]>(
        `${this.baseUrl}/test-exam/history`
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getImageResource(resourceId: number): Observable<Blob> {
    return this
      .http.get(
        `${this.baseUrl}/resources/images/${resourceId}`,
        { responseType: 'blob' }
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getPdfResource(resourceId: number): Observable<Blob> {
    return this
      .http.get(
        `${this.baseUrl}/resources/pdfs/${resourceId}`,
        { responseType: 'blob' }
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getMyCourses(): Observable<StudentCourse[]> {
    return this.http.get<StudentCourse[]>(
      `${this.baseUrl}/student_courses/my_courses`
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getMyDocuments(): Observable<MyDocument[]> {
    return this
      .http.get<MyDocument[]>(
        `${this.baseUrl}/student_document_list/my_documents`
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getCourse(courseId: number): Observable<Course> {
    return this
      .http.get<Course>(
        `${this.baseUrl}/courses/${courseId}`
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  postDocument(image: File, imageType: string): Observable<any> {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('imageType', imageType);

    return this.http.post(
      `${this.baseUrl}/student_document`, formData
    )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getDocumentImage(studentDocumentId: number): Observable<Blob> {
    return this
      .http.get(
        `${this.baseUrl}/student_documents/${studentDocumentId}`,
        { responseType: 'blob' }
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteDocument(studentDocumentId: number): Observable<any> {
    return this
      .http.delete(
        `${this.baseUrl}/student_documents/${studentDocumentId}`
      )
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getMyRegistrations(): Observable<Registration[]> {
    return this.http.get<Registration[]>(
      `${this.baseUrl}/exam_registration_list/my_registrations`
    ).pipe(
      map((registrations) => registrations.map(
        (registration) => {
          registration.examCalendarDTO.endTime =
            this.datePipe.transform(new Date(registration.examCalendarDTO.endTime), 'yyyy MM dd HH:mm');
          registration.examCalendarDTO.startTime =
            this.datePipe.transform(new Date(registration.examCalendarDTO.startTime), 'yyyy MM dd HH:mm');
          return registration;
        })
      ),
      catchError(this.handleError.bind(this))
    );
  }

  getExamLocationsAndTimes(examTypeCodeId: number): Observable<ExamCalendar[]> {
    return this.http.get<ExamCalendar[]>(
      `${this.baseUrl}/exam_calendar/type/${examTypeCodeId}`
    ).pipe(
      map((examCalendar) => examCalendar.map(
        (time) => {
          time.startTime = this.datePipe.transform(new Date(time.startTime), 'yyyy MM dd HH:mm');
          time.endTime = this.datePipe.transform(new Date(time.endTime), 'yyyy MM dd HH:mm');
          return time;
        }
      )),
      catchError(this.handleError.bind(this))
    );
  }

  registerToExam(studentCourseId: number, examCalendarId: number): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/exam_registration`, { studentCourseId, examCalendarId }
    ).pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getStatistics(studentCourseId: number): Observable<Statistics[]> {
    return this.http.get<Statistics[]>(
      `${this.baseUrl}/statistics/test_exams/${studentCourseId}`
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getExamTypes(): Observable<ExamTypeDTO[]> {
    return this.http.get<ExamTypeDTO[]>(`${this.baseUrl}/exam_types`
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getChapterStatistics(studentCourseId: number, chapterId: number): Observable<ChapterStatistics> {
    return this.http.get<ChapterStatistics>(`${this.baseUrl}/statistics/student_course/${studentCourseId}/chapter/${chapterId}`
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  postFeedback(feedback: Feedback): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/feedback`, feedback
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getOAuthToken(token: string): Observable<OAuth> {
    return this.http.post<OAuth>(
      `${this.basePublicUrl}/authorize`,
      { token }
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getRefreshToken(token: string): Observable<OAuth> {
    return this.http.post<OAuth>(
      `${this.basePublicUrl}/refresh`,
      { token }
    ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  // TODO http error handling
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.notificationService.sendMessage({
        message: 'Hoppsz ez valahol elúszott! Kérjük próbálkozz később!',
        type: NotificationType.error
      });
      console.error('An error occurred:', error.error.message);
    } else if (error.error instanceof Blob) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const errorJson = JSON.parse(e.target.result);
        this.notificationService.sendMessage({
          message: errorJson.message,
          type: NotificationType.error
        });
      };
      reader.readAsText(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if (error.status === 401) {
        window.location.reload();
      } else {
        this.notificationService.sendMessage({
          message: error.error.exceptionMessage ? error.error.exceptionMessage : error.error.message,
          type: NotificationType.error
        });
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.error({error});
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
