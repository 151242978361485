import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NetworkService} from '../../network/services/network.service';
import {Feedback} from '../../network/models/Feedback';
import {NotificationType} from '../../notification/NotificationType';
import {NotificationService} from '../../notification/notification.service';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {

  @Input() feedbackCategory: string;
  @Input() questionId: number;
  feedbackCategories = ['Tananyag', 'Gyakorlókérdés', 'Általános'];

  feedbackSubject: string;
  feedbackText: string;

  @ViewChild('closeButton') closeButton: ElementRef;

  constructor(private network: NetworkService, private notificationService: NotificationService) { }

  ngOnInit(): void {
  }

  sendFeedback(): void {
    const feedback = new Feedback();
    feedback.category = this.feedbackCategory;
    feedback.subject = this.feedbackSubject;
    feedback.text = this.feedbackText;
    if (this.questionId) {
      feedback.text += '\nA kérdés azonosítója: ' + this.questionId;
    }
    this.network.postFeedback(feedback).subscribe(() => {
      this.feedbackCategory = null;
      this.feedbackSubject = null;
      this.feedbackText = null;
      this.notificationService.sendMessage({
        message: 'Visszajelzés elküldve!',
        type: NotificationType.success
      });
    });
    this.closeButton.nativeElement.click();
  }
}
