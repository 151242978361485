<div class="modal-header text-center d-block">
  <button #closeButton type="button" class="close close-with-center-text" data-dismiss="modal">
    <img class="close-icon" src="assets/images/modal-close-icon.svg" alt="">
  </button>
  <span class="modal-title title-text">
    Hibabejelentés
  </span>
</div>
<form (ngSubmit)="sendFeedback()" #feedbackForm="ngForm">
  <div class="modal-body">
    <div class="form-group">
      <div class="mt-2 modal-text">
        Hiba típusa
      </div>
      <div>
        <ng-select id="feedback"
                   name="feedback"
                   placeholder="Válassz hibatípust"
                   [(ngModel)]="feedbackCategory"
                   [items]="feedbackCategories"
                   required
                   #category="ngModel">
        </ng-select>
        <div [hidden]="category.valid || category.pristine || category.untouched" class="alert alert-danger">
          A hiba típusának megadása kötelező
        </div>
      </div>
      <div class="mt-2 modal-text">
        Tárgy
      </div>
      <div>
        <input class="form-control"
               placeholder="Az üzenet tárgya"
               [(ngModel)]="feedbackSubject"
               required
               minlength="5"
               id="subject"
               name="subject"
               #subject="ngModel">
        <div [hidden]="subject.valid || subject.pristine || subject.untouched" class="alert alert-danger">
          Az üzenet tárgya minimum 5 karakter!
        </div>
      </div>
      <div class="mt-2 modal-text">
        Hiba részletes leírása
      </div>
      <div>
        <textarea name="text"
                  id="text"
                  #text="ngModel"
                  rows="10"
                  class="form-control"
                  placeholder="A hiba leírása"
                  [(ngModel)]="feedbackText"
                  required minlength="10" maxlength="5000">
        </textarea>
        <div [hidden]="text.valid || text.pristine || text.untouched" class="alert alert-danger">
          Az üzenet törzse minimum 10, maximum 5000 karakter!
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-center">
    <button class="button-outline-big" routerLink="/" data-dismiss="modal">
      Mégsem
    </button>
    <button type="submit"
      class="button-primary-big ml-2"
            [disabled]="!(feedbackForm.valid)"
            [ngClass]="!(feedbackForm.valid) ? 'disabled-opacity' : ''">
      Küldés
    </button>
  </div>
</form>
