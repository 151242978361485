<div class="card main-container">
  <div class="card-body">
    <div>
      <div class="card-title title-text">Hajóbérlés</div>
      <div class="text-center">
        <img class="image" src="../../../../../assets/images/rent-test.png" alt="">
      </div>
      <div class="row mt-3 pb-3">
        <div class="col-4 d-flex reduce-start-padding align-items-center">
          <span class="row-image-container-white mr-2">
            <img class="row-image" src="../../../../../assets/images/wheel-icon.png" alt="">
          </span>
          <div class="d-inline-block">
            <div class="rent-number-text">
              2006
            </div>
            <div class="rent-desc-text m">
              Évjárat
            </div>
          </div>
        </div>
        <div class="col-4 d-flex align-items-center">
          <span class="row-image-container-white mr-2">
            <img class="row-image" src="../../../../../assets/images/arrows-icon.png" alt="">
          </span>
          <div class="d-inline-block">
            <div class="rent-number-text">
              32’
            </div>
            <div class="rent-desc-text m">
              Hossz
            </div>
          </div>
        </div>
        <div class="col-4 d-flex reduce-end-padding align-items-center">
          <span class="row-image-container-white mr-2">
            <img class="row-image" src="../../../../../assets/images/people-icon.png" alt="">
          </span>
          <div class="d-inline-block">
            <div class="rent-number-text">
              10
            </div>
            <div class="rent-desc-text m">
              Férőhely
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex reduce-start-padding align-items-center">
          <span class="row-image-container-white mr-2">
            <img class="row-image" src="../../../../../assets/images/bed-icon.png" alt="">
          </span>
          <div class="d-inline-block">
            <div class="rent-number-text">
              6
            </div>
            <div class="rent-desc-text m">
              Szállás
            </div>
          </div>
        </div>
        <div class="col-8 d-flex align-items-center">
          <span class="row-image-container-white mr-2">
            <img class="row-image" src="../../../../../assets/images/pin-icon.png" alt="">
          </span>
          <div class="d-inline-block">
            <div class="rent-number-text">
              Balatonfüred
            </div>
            <div class="rent-desc-text m">
              Helyszín
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <button class="button-primary-big" (click)="goToRentShips()">
          Mutasd az összes hajót
          <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
        </button>
      </div>
    </div>
    <div class="mt-4">
      <div class="card-title title-text">Vizenjó blog</div>
      <div class="text-center">
        <img class="image" src="../../../../../assets/images/blog-test.png" alt="">
      </div>
      <div class="blog-text mt-3">
        Miért olyan nehezek a kikötői manőverek? Mert szűk a hely, mert sok a hajó...
      </div>
      <div class="text-center mt-3">
        <button class="button-primary-big" (click)="goToBlog()">
          Elolvasom
          <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
        </button>
      </div>
    </div>
  </div>
</div>
