import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarModule} from 'angular-bootstrap-md';
import { PracticeDashboardComponent } from './components/dashboard/components/practice-dashboard/practice-dashboard.component';
import { StatisticsComponent } from './components/dashboard/components/statistics/statistics.component';
import { ExamTimeComponent } from './components/dashboard/components/exam-time/exam-time.component';
import { RentAndBlogComponent } from './components/dashboard/components/rent-and-blog/rent-and-blog.component';
import { EduMaterialComponent } from './components/edu-material/edu-material.component';
import { ExamComponent } from './components/exam/exam.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule} from '@angular/forms';
import { ExamSelectorComponent } from './components/exam-selector/exam-selector.component';
import { DragDropDirective } from './components/dashboard/components/exam-time/directives/drag-drop.directive';
import {NgSelectModule} from '@ng-select/ng-select';
import { ResultComponent } from './components/result/result.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RedirectGuard} from './network/guards/redirect.guard';
import {TokenInterceptor} from './network/interceptors/token.interceptor';
import {AuthGuard} from './network/guards/auth-guard.service';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { PracticeComponent } from './components/practice/practice.component';
import {ToastrModule} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {CustomDatePipe} from './components/dashboard/components/exam-time/pipes/CustomDatePipe';
import {NoSanitizePipe} from './components/edu-material/pipes/NoSanitize';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NavbarComponent,
    PracticeDashboardComponent,
    StatisticsComponent,
    ExamTimeComponent,
    RentAndBlogComponent,
    EduMaterialComponent,
    ExamComponent,
    ExamSelectorComponent,
    DragDropDirective,
    ResultComponent,
    PracticeComponent,
    CustomDatePipe,
    NoSanitizePipe,
    FeedbackModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    NoopAnimationsModule,
    NavbarModule,
    NgCircleProgressModule.forRoot({
      radius: 30,
      space: -7,
      outerStrokeWidth: 7,
      innerStrokeWidth: 7,
      outerStrokeColor: '#355681',
      innerStrokeColor: '#CFD8DC',
      animation: false,
      animationDuration: 300,
      showSubtitle: false,
      showUnits: false,
      titleFontSize: '14px',
      titleColor: '#355681',
      renderOnClick: false,
    }),
    MatRadioModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ToastrModule.forRoot(),
    PdfViewerModule,
    NgProgressModule.withConfig({
      color: 'blue',
      spinner: true,
      thick: true
    }),
    NgProgressHttpModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuard,
    RedirectGuard,
    DatePipe,
    NoSanitizePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
