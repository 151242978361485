<app-navbar topTitle="Tesztvizsga" pageTitle="Eredmény">
</app-navbar>
<div class="parent-container">
  <div class="main-container">
    <ng-container *ngIf="testExamSummary">
      <div class="exam-container">
        <div class="ellipse-container">
          <img src="assets/images/blue-ellipse.svg" alt="ellipse">
        </div>
        <div class="result-container d-flex justify-content-center align-items-center">
          <div>
            <ng-container *ngIf="testExamSummary.status === 'TEST_SUCCESSFUL' else testFailed">
              <div>
                <div>
                  <img src="assets/images/result-success-icon.svg" alt="success">
                </div>
                <div class="success-text">
                  Sikeres teszt
                </div>
              </div>
            </ng-container>
            <ng-template #testFailed>
              <div>
                <img src="assets/images/result-failed-icon.svg" alt="success">
              </div>
              <div class="failed-text">
                Sikertelen teszt
              </div>
            </ng-template>
            <div class="result-box mt-5">
              <div class="row">
                <div class="col-lg-3 col-md-6 col-6 pl-lg-5 pl-md-2 pl-2 pr-lg-5 pr-md-2 pr-2 d-flex justify-content-center align-items-start">
                  <div>
                    <div class="number-text">
                      {{ testExamSummary.resultPercent | number:'1.0-0'}} %
                    </div>
                    <div class="description-text">
                      Eredmény
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pl-lg-5 pl-md-2 pl-2 pr-lg-5 pr-md-2 pr-2 d-flex justify-content-center align-items-start">
                  <div>
                    <div class="number-text">
                      {{ testExamSummary.sumPoints }}
                    </div>
                    <div class="description-text">
                      Kérdések összpontszáma
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 mt-md-2 mt-2 pl-lg-5 pl-md-2 pl-2 pr-lg-5 pr-md-2 pr-2 d-flex justify-content-center align-items-start">
                  <div>
                    <div class="number-text">
                      {{ spentTimeMinutes }} : {{ spentTimeSeconds }}
                    </div>
                    <div class="description-text">
                      Teljesített idő
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pl-lg-5 pl-md-2 pl-2 pr-lg-5 pr-md-2 pr-2 d-flex justify-content-center align-items-start">
                  <div>
                    <div class="number-text">
                      {{ testExamSummary.errorPoints }}
                    </div>
                    <div class="description-text">
                      Helytelen válaszok összpontszáma
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="question-parent-container w-100 p-3" *ngIf="desktopView; else mobileResult">
              <div class="question-main-container">
                <mat-expansion-panel togglePosition="before" *ngFor="let questionSummary of testExamSummary.questionList; let i=index;"
                  [expanded]="openedIdx === i" (opened)="onPanelOpened(questionSummary, i)"
                  [ngStyle]="{'background-color': questionSummary?.selectedAnswer?.answerId === questionSummary?.correctAnswer?.answerId ? 'white' : '#FEFAFA'}">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100 align-items-center">
                        <div class="col-md-8 text-left">
                          <span>{{ i+1 + '. feladat' }}</span>
                        </div>
                        <div class="px-0 col-md-2 offset-md-2 text-left"
                            *ngIf="questionSummary?.selectedAnswer?.answerId === questionSummary?.correctAnswer?.answerId; else incorrectStatus">
                          <img src="assets/images/correct.svg" alt="" class="ml-2">
                          <span class="correct-text ml-2">Helyes</span>
                        </div>
                        <ng-template #incorrectStatus >
                          <div class="px-0 col-md-2">
                            <span>{{ questionSummary?.errorPoints + ' hibapont' }}</span>
                          </div>
                          <div class="px-0 col-md-2 text-md-left">
                            <img src="assets/images/incorrect.svg" alt="" class="ml-2">
                            <span class="incorrect-text ml-2">Helytelen</span>
                          </div>
                        </ng-template>

                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="expansion-panel-body-header row">
                    <div class="col-4">
                      <span>Kérdés</span>
                    </div>
                    <div class="col-4">
                      Adott válasz
                    </div>
                    <div class="col-4">
                      Helyes válasz
                    </div>
                  </div>
                  <div class="row question-item-common-text">
                    <div class="col-4">
                      <span>
                        {{ questionSummary.description }}
                      </span>
                      <ng-container *ngIf="imageToShow">
                        <div class="text-center mt-4">
                          <img class="help-image" [src]="imageToShow" alt="help image">
                        </div>
                      </ng-container>
                    </div>
                    <div class="col-4">
                      <span>
                        {{ questionSummary?.selectedAnswer?.description }}
                      </span>
                    </div>
                    <div class="col-4">
                      <span>
                        {{ questionSummary?.correctAnswer?.description }}
                      </span>
                    </div>
                  </div>

                </mat-expansion-panel>
              </div>
            </div>

            <ng-template #mobileResult>
              <div class="question-parent-container p-3">
                <div class="question-main-container">
                  <mat-expansion-panel togglePosition="before" *ngFor="let questionSummary of testExamSummary.questionList; let i=index;"
                      [expanded]="openedIdx === i" (opened)="onPanelOpened(questionSummary, i)"
                      [ngStyle]="{'background-color': questionSummary?.selectedAnswer?.answerId === questionSummary?.correctAnswer?.answerId ? 'white' : '#FEFAFA'}">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="row w-100 align-items-center">
                          <div class="col-6 text-left">
                            <span>{{ i+1 + '. feladat' }}</span>
                          </div>
                          <div class="col-6 pr-0 text-right" *ngIf="questionSummary?.selectedAnswer?.answerId === questionSummary?.correctAnswer?.answerId; else incorrectStatusImg">
                            <img src="assets/images/correct.svg" alt="" class="question-status-img ml-2">
                          </div>
                          <ng-template #incorrectStatusImg>
                            <div class="col-6 pr-0 text-right">
                              <img src="assets/images/incorrect.svg" alt="" class="question-status-img ml-2">
                            </div>
                          </ng-template>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="question-item-title-text">
                      <span>Kérdés</span>
                    </div>
                    <div class="question-item-common-text">
                      <span>
                        {{ questionSummary.description }}
                      </span>
                      <ng-container *ngIf="imageToShow">
                        <div class="text-center mt-4">
                          <img class="help-image" [src]="imageToShow" alt="help image">
                        </div>
                      </ng-container>
                    </div>
                    <hr/>
                    <div>
                      <div class="question-item-title-text">
                        Adott válasz
                      </div>
                      <div class="question-item-common-text">
                        <span>
                          {{ questionSummary?.selectedAnswer?.description }}
                        </span>
                      </div>
                    </div>
                    <hr/>
                    <div>
                      <div class="question-item-title-text">
                        Kapott válasz
                      </div>
                      <div class="question-item-common-text">
                        <span>
                          {{ questionSummary?.correctAnswer?.description }}
                        </span>
                      </div>
                    </div>
                    <hr/>
                    <div>
                      <div class="question-item-title-text">
                        Státusz
                      </div>
                      <div class="text-left"
                          *ngIf="questionSummary?.selectedAnswer?.answerId === questionSummary?.correctAnswer?.answerId; else incorrectStatus">
                        <img src="assets/images/correct.svg" alt="" class="">
                        <span class="correct-text ml-2">Helyes</span>
                      </div>
                      <ng-template #incorrectStatus >
                        <div class="text-left">
                          <img src="assets/images/incorrect.svg" alt="" class="">
                          <span class="incorrect-text ml-2">Helytelen</span>
                        </div>
                      </ng-template>
                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
            </ng-template>

            <div class="mt-5">
              <button class="button-outline-big" routerLink="/">
                Vissza a főoldalra
              </button>
              <button class="ml-lg-2 ml-md-0 ml-0 mt-md-2 mt-2 button-primary-big" routerLink="/test-exam"
                      [queryParams]="{ studentCourseId: studentCourseId, topicId: topicId }">
                Új Tesztvizsga indítása
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
