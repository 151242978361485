// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: window["env"]["baseUrl"] || 'https://vizenjo-backend.rancher.lippert.dev/api/v1/protected',
  basePublicUrl: window["env"]["basePublicUrl"] || 'https://vizenjo-backend.rancher.lippert.dev/api/v1/public',
  production: window["env"]["production"] || false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// PROD
// production: true,
// baseUrl: 'https://dev.lippert.hu:10443/api/v1/protected',
// basePublicUrl: 'https://dev.lippert.hu:10443/api/v1/public'
