<app-navbar topTitle="Témakörök" [pageTitle]="courseName">
</app-navbar>
<div class="parent-container">
  <div class="main-container">
    <div *ngIf="topic" class="edu-material-container">
      <div>
        <a class="back-link" routerLink="">
          <img class="mr-2" src="../../../assets/images/arrow-left-icon.svg" alt="">Vissza a főoldalra</a>
      </div>
      <div class="mt-lg-0 mt-md-4 mt-4 d-block exam-selector-header-text">
        Válasszd ki a gyakorolni kívánt alfejeztet!
      </div>
      <div class="edu-accordion-container-top d-flex justify-content-center align-items-center">
        <div class="edu-accordion-container">
          <mat-expansion-panel class="mt-4" *ngFor="let subTopic of topic.subtopics">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row" style="width: 100%">
                  <div class="col-lg-8 col-md-12 col-12">
                    <span class="accordion-header-text">
                      {{ subTopic.subtopicName }}
                    </span>
                  </div>
                  <div class="col-4 d-lg-flex d-md-none d-none align-items-center justify-content-center">
                    <div class="progress topic-progress">
                      <div class="progress-bar"
                           role="progressbar"
                           [ngStyle]="{ width: subTopic.learnedPercent+'%' }"
                           [ngClass]="subTopic.learnedPercent < 40 ? 'red-background' : subTopic.learnedPercent < 80 ? 'blue-background' : 'green-background'"
                           aria-valuemin="0"
                           aria-valuemax="100">
                      </div>
                    </div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mb-3">
              <div
                   *ngFor="let chapter of subTopic.chapters; let i = index"
                   (click)="selectChapter(chapter)"
                   data-toggle="modal"
                   data-target="#examSelectorConfirm"
                   [ngClass]="chapter.learnedPercent === 100 ? 'green-background-2' : ''">
                <div *ngIf="chapter.questionFl" class="chapter-container pt-2 pb-2">
                  <div class="row pr-2 pl-4">
                    <div class="col-9">
                    <span class="accordion-chapter-text">
                      {{ chapter.chapterName }}
                    </span>
                    </div>
                    <div class="col-3 d-flex align-items-center justify-content-center">
                      <div class="progress topic-progress">
                        <div class="progress-bar"
                             role="progressbar"
                             [ngStyle]="{ width: chapter.learnedPercent+'%' }"
                             [ngClass]="chapter.learnedPercent < 40 ? 'red-background' : chapter.learnedPercent < 80 ? 'blue-background' : 'green-background'"
                             aria-valuemin="0"
                             aria-valuemax="100">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ExamSelectConfirm Modal -->
<div id="examSelectorConfirm" class="modal fade" role="dialog" *ngIf="selectedChapter">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <img class="info-image" src="assets/images/modal-info-icon.svg" alt="" >
      <div class="modal-header text-center">
        <button type="button" class="close" data-dismiss="modal"><img class="close-icon" src="assets/images/modal-close-icon.svg" alt=""></button>
      </div>
      <div class="modal-body text-center">
        <div class="modal-chapter-text">
          {{ selectedChapter.chapterName }}
        </div>
        <div class="mt-2 modal-text">
          <span *ngIf="selectedChapter.learnedPercent !== 100 else completedText">
            A következőkben elindul a kiválasztott fejezethez tartozó kérdés sor gyakorlása.
          </span>
          <ng-template #completedText>
            <span>
              Ezt az alfejezetet már sikeresen megtanultad!
            </span>
          </ng-template>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button class="button-outline-big" data-dismiss="modal">
          Másikat választok
        </button>
        <button *ngIf="selectedChapter.learnedPercent !== 100" class="button-primary-big ml-2"
                routerLink="/practice"
                [queryParams]="{ studentCourseId: studentCourseId, topicId: topicId, chapterId: selectedChapter.chapterId }" data-dismiss="modal">
          Gyakorlás indítása
        </button>
      </div>
    </div>
  </div>
</div>
