import {Component, Input, OnInit} from '@angular/core';
import {NetworkService} from '../../../../network/services/network.service';
import {StudentCourse} from '../../../../network/models/StudentCourse';
import {HierarchyTopic} from '../../../../network/models/HierarchyTopic';
import {TopicTypes} from './enums/TopicTypes';
import {TopicHasPracticeDTO} from '../../../../network/models/TopicHasPracticeDTO';

@Component({
  selector: 'app-practice-dashboard',
  templateUrl: './practice-dashboard.component.html',
  styleUrls: ['./practice-dashboard.component.scss']
})
export class PracticeDashboardComponent implements OnInit {

  selectedExamId = null;

  @Input() course: StudentCourse;
  topics: HierarchyTopic[] = null;

  sailBoatStudentCourseId: number;
  boatStudentCourseId: number;
  conditionsCourseId: number;

  filteredTopicList: HierarchyTopic[] = null;

  constructor(private network: NetworkService) { }

  ngOnInit(): void {
    this.selectedExamId = null;

    this.network.getTopicHierarchy(this.course.studentCourseId).subscribe(hierarchy => {
        this.topics = hierarchy;
        this.sailBoatStudentCourseId = this.getTopicId(TopicTypes.VitorlasKishajo);
        this.boatStudentCourseId = this.getTopicId(TopicTypes.Kisgephajo);
        this.conditionsCourseId = this.getTopicId(TopicTypes.HajozasiSzabalyzat);

        this.filteredTopicList = hierarchy.filter(t => t.hasPractice);
      });
  }

  selectExamType(topicId: number): void {
        this.selectedExamId = topicId;
  }

  getCoursePictogram(topicName: string): string {
    switch (topicName) {
      case TopicTypes.VitorlasKishajo:
        return '/assets/images/sailboat-icon.svg';
        break;
      case TopicTypes.Kisgephajo:
        return '/assets/images/boat-icon.svg';
        break;
      case TopicTypes.HajozasiSzabalyzat:
        return '/assets/images/conditions-icon.svg';
        break;
    }
    return '/assets/images/conditions-icon.svg';
  }

  getCoursePictogramWhite(topicName: string): string {
    switch (topicName) {
      case TopicTypes.VitorlasKishajo:
        return '/assets/images/sailboat-icon-white.svg';
        break;
      case TopicTypes.Kisgephajo:
        return '/assets/images/boat-icon-white.svg';
        break;
      case TopicTypes.HajozasiSzabalyzat:
        return '/assets/images/conditions-icon-white.svg';
        break;
    }
    return '/assets/images/conditions-icon-white.svg';
  }

  getTopicId(topicName: string): number {
    let topicId = null;
    this.topics.forEach(topic => {
      if (topic.topicName === topicName) {
        topicId = topic.topicId;
      }
    });
    return topicId;
  }

}
