<div class="card main-container">
  <div class="card-body d-flex align-items-center">
    <div class="flex-fill">
      <div class="card-title title-text">Statisztikád - Tesztvizsgák</div>
      <ng-container *ngIf="statistics && statistics.length > 0 else noStatAvailable">
        <div class="row">
          <div class="col-4 d-flex align-items-center justify-content-center">
            <div>
              <div class="stat-title-text text-center">
                Össz értékekelés
              </div>
              <div class="stat-number-text">
                {{ total | number:'1.0-0'}}%
              </div>
            </div>
          </div>
          <div class="col-4 blue-left-border d-flex align-items-center justify-content-center">
            <div>
              <div class="stat-title-text text-center">
                Sikeres tesztek száma
              </div>
              <div class="stat-number-text">
                {{ successful }}
              </div>
            </div>
          </div>
          <div class="col-4 blue-left-border d-flex align-items-center justify-content-center">
            <div>
              <div class="stat-title-text text-center">
                Sikertelen tesztek száma
              </div>
              <div class="stat-number-text">
                {{ unSuccessful }}
              </div>
            </div>
          </div>
        </div>
        <hr class="line">
        <div *ngFor="let stat of statistics" class="row pt-1 pb-1">
          <div class="col-4 d-flex align-items-center text-left">
            <span class="progress-title-text">
              {{ stat.topicName }}
            </span>
          </div>
          <div class="col-8">
            <div class="progress stat-progress">
              <div class="progress-bar stat-progress-bar"
                   [ngStyle]="{ width: stat.lastThreeTestAverageSuccessRate+'%' }"
                   [ngClass]="stat.lastThreeTestAverageSuccessRate < 40 ? 'red-background' : stat.lastThreeTestAverageSuccessRate < 80 ? 'blue-background' : 'green-background'"
                   role="progressbar"
                   aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
            <div class="stat-number" [ngClass]="stat.lastThreeTestAverageSuccessRate < 40 ? 'red-color' : stat.lastThreeTestAverageSuccessRate < 80 ? 'blue-color' : 'green-color'">
              {{ stat.lastThreeTestAverageSuccessRate | number:'1.0-0'}}%
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noStatAvailable>
        <div class="row">
          <div class="col-5 d-flex justify-content-end align-items-center">
            <img class="image" src="assets/images/no-statistics.svg" alt="">
          </div>
          <div class="col-7 d-flex justify-content-start align-items-center">
            <div>
              <div class="text-left">
                Jelenleg nem áll rendelkezésre elég adat a statisztikád felállításához.
              </div>
              <div>
                <button class="button-primary-big mt-3" data-toggle="modal" data-target="#examSelect">
                  Elkezdem
                  <img class="ml-2" src="../../../../../assets/images/button-arrow-icon.png" alt="">
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
